import React from "react";

const Cartnew = (props) => (

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        {...props}
    >
        <circle cx="24" cy="24" r="24" fill="#fff"></circle>
        <path
            fill="currentColor"
            d="M35.767 15.359A1 1 0 0035 15H14.835l-.611-3.357A2 2 0 0012.256 10H10a1 1 0 000 2h2.25l3.195 17.536a3 3 0 00.666 1.409A3.5 3.5 0 1021.661 32h5.678a3.5 3.5 0 103.161-2H18.396a1 1 0 01-.983-.821L17.016 27h14.5a3 3 0 002.952-2.464l1.52-8.357a1 1 0 00-.22-.82zM20 33.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm12 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm.5-9.321a1 1 0 01-.988.821h-14.86L15.2 17H33.8L32.5 24.179z"
        ></path>
    </svg>
);

export default Cartnew;
