import store from '../store'
import { startLoading, getCatalogFailure, getCatalogSuccess } from '../slices/catalogSlice';
import { getAddressDetailsSuccess, getFailure, getLocations, getOpenAddressDetailsModel, getPinCodeDetails, startLoadingMap, selectAddressMapLoading } from '../slices/mapSlice';

import API from '.';
import { GET_HOME_CATALOG } from './apiList';
import axios from 'axios';
import { handleError } from '../components/common/utils';


// Actions
export const fetchCatalog = async (body) => {
    // console.log('FETCHING CATALOG : STARTING LOADING')
    try {
        // console.log('FETCHING CATALOG : STARTING LOADING', body)
        store.dispatch(startLoading(true));
        const response = await API.post(GET_HOME_CATALOG, body);
        store.dispatch(getCatalogSuccess(response?.data?.data));
        // console.log('FETCHING CATALOG : STOP LOADING', response?.data?.data)

        store.dispatch(startLoading(false));
    } catch (error) {
        // console.log("FETCHING CATALOG : ERR ", error)
        store.dispatch(getCatalogFailure());
        store.dispatch(startLoading(false));
    }
};


export const getPlacesDetails = async (obj) => {
    // console.log('obj-->', obj)
    store.dispatch(startLoadingMap(true))
    var config = {
        method: "get",
        url:
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            obj.latitude +
            "," +
            obj.longitude +
            "&key=" + process.env.REACT_APP_GOOGLE_PLACES_DETAILS,

    };
    let response = await axios(config);
    // console.log('response-->', response)
    store.dispatch(selectAddressMapLoading(false))
    store.dispatch(startLoadingMap(false))
    if (response?.data?.results.length > 0) {
        // console.log('In Sucuss')
        const place_detail = response?.data.results[0];
        const postal_code = place_detail.address_components.findIndex((ele) =>
            ele.types.includes("postal_code")
        );
        const zipcode =
            postal_code !== -1
                ? place_detail.address_components[postal_code].long_name
                : "";
        // const country_level = place_detail.address_components.findIndex((ele) =>
        //     ele.types.includes("country")
        // );
        // const country =
        //     country_level !== -1
        //         ? place_detail.address_components[country_level].long_name
        //         : "";
        // const sublocality_level_1 = place_detail.address_components.findIndex(
        //     (ele) => ele.types.includes("sublocality_level_1")
        // );
        // const nearBy =
        //     sublocality_level_1 !== -1
        //         ? place_detail.address_components[sublocality_level_1].long_name
        //         : "";
        const administrative_area_level_3 =
            place_detail.address_components.findIndex((ele) =>
                ele.types.includes("administrative_area_level_3")
            );
        const administrative_area_level_1 =
            place_detail.address_components.findIndex((ele) =>
                ele.types.includes("administrative_area_level_1")
            );
        const city =
            administrative_area_level_3 !== -1
                ? place_detail.address_components[administrative_area_level_3].long_name
                : "";
        const state =
            administrative_area_level_1 !== -1
                ? place_detail.address_components[administrative_area_level_1].long_name
                : "";


        const addressDetails = {
            latitude: obj.latitude,
            longitude: obj.longitude,
            state: state,
            city: city,
            pincode: zipcode,
            place_detail: place_detail.formatted_address,
            title: place_detail.formatted_address,
            address_line2: place_detail.formatted_address,
            locations: []
        }
        // console.log("API SERVICE : PLACE 11", addressDetails);
        store.dispatch(getAddressDetailsSuccess(addressDetails))
        store.dispatch(getOpenAddressDetailsModel(true))

    } else {
        store.dispatch(startLoadingMap(false))
        console.log('In failure')
        store.dispatch(selectAddressMapLoading(false))
        store.dispatch(getFailure())
    }
};
export const getPincodeDetail = async (pincode) => {
    try {
        if (pincode.length > 4) {
            const options = {
                method: 'post',
                url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/maps/getpicodedetails`,
                data: { pincode: pincode }
            }
            const { data } = await axios(options)
            if (data.data.city) {
                localStorage.setItem("city", data.data.city ? data.data.city : "Current Location")
                localStorage.setItem("pincode", data.data.pincode ? data.data.pincode : "")
                const addressDetails = {
                    latitude: data.data.latitude ? data.data.latitude : 0.0,
                    longitude: data.data.city ? data.data.city : 0.0,
                    state: data.data.state ? data.data.state : "",
                    city: data.data.city ? data.data.city : "",
                    pincode: data.data.pincode ? data.data.pincode : 0,
                }
                store.dispatch(getPinCodeDetails(addressDetails))
            }
        }
    }
    catch (error) {
        console.log("obj-=-=-=-=-=>", error)
        // handleError(error);
    }
}
export const placeDetail = async (placeId) => {
    try {
        store.dispatch(selectAddressMapLoading(true))
        const mapToken = await mapTokenGenerator();
        const token = JSON.parse(localStorage.getItem("token")) || ""
        const options = {
            method: 'post',
            url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/maps/getdetails`,
            headers: {
                'Authorization': `${token}`,
                desktop: true,
            },
            data: { placeId: placeId, token: mapToken }
        }
        const data = await axios(options)
        // console.log("API SERVICE : PLACE DETAILS DATA", data)
        if (data?.data?.data["geometry"]) {
            const obj = {
                latitude: data?.data?.data?.geometry?.location?.lat,
                longitude: data?.data?.data?.geometry?.location?.lng,
            }
            // console.log("API SERVICE : PLACE DETAILS LAT", data?.data?.data?.geometry?.location?.lat)
            getPlacesDetails(obj)
        } else {
            store.dispatch(selectAddressMapLoading(false))
            alert("Please select another address")
        }
        // else { placeDetail(placeId) }
        // console.log("obj-=-=-=-=-=>",obj)
    }
    catch (error) {
        handleError(error);
        store.dispatch(selectAddressMapLoading(false))
    }
}

export const handleAddress = async (address) => {
    // console.log("API SERVICE : HANDLE ADDRESS ", address);
    const mapToken = await mapTokenGenerator();
    const token = JSON.parse(localStorage.getItem("token")) || ""
    store.dispatch(getLocations([]))
    if (address.length > 2) {
        try {
            // console.log("API SERVICE : HANDLE ADDRESS ", address);
            store.dispatch(startLoadingMap(true))
            const options = {
                method: 'post',
                url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/maps/autocomplete-v2`,
                headers: {
                    'Authorization': `${token}`,
                    desktop: true,
                },
                data: { search: address, token: mapToken }
            }
            const data = await axios(options)
            // console.log("API SERVICE : HANDLE ADDRESS 11", data);
            if (data?.data?.success) {
                // console.log("API SERVICE : HANDLE ADDRESS 22", data);
                store.dispatch(getLocations(data.data.data))
            }
            store.dispatch(startLoadingMap(false))
        } catch (error) {
            // handleError(error);
            store.dispatch(startLoadingMap(false))
        }
    }
};

export const mapTokenGenerator = async () => {
    const token = JSON.parse(localStorage.getItem("token")) || ""
    const options = {
        method: 'get',
        url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/maps/gettoken`,
        headers: {
            'Authorization': `${token}`,
            desktop: true,
        },
    }
    return axios(options)
        .then(({ data }) => {
            if (data) {
                return data?.data;
            } else {
                throw new Error("Token data is missing");
            }
        })
        .catch((error) => {
            // handleError(error);
        });
}