import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import "./styles.scss";

function Map(props) {
  // console.log("props-=-=-=-", props.currLatlong1);

  const passAr = (latlng) => {
    var objmap = {};
    objmap.latitude = latlng.latitude;
    objmap.longitude = latlng.longitude;
    props.func(objmap);
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "15px"
  };

  const center = {
    lat: props.currLatlong1.latitude ? props.currLatlong1.latitude : 22.7196,
    lng: props.currLatlong1.longitude ? props.currLatlong1.longitude : 75.8577,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [zoom, setZoom] = React.useState(10);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    // console.log("data console",bounds)
   setTimeout(() => {
    setZoom(18)
   }, 100); 
    // map.setZoom(15)
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        defaultCenter={center}
        zoom={zoom} 
        onDragEnd={() => {
          passAr({
            latitude: map.getCenter().lat(),
            longitude: map.getCenter().lng(),
          });
        }}
        options={{ gestureHandling: "greedy" }}
        onLoad={onLoad}
        onUnmount={onUnmount}
// on={() =>     map.setZoom(15)}
      ></GoogleMap>
      <div className="markerbg">
        <h4>Your Order will delivered here</h4>
        <p>Place the pin accurate</p>
      </div>
    </>
  ) : (
    <></>
  );
}
export default Map;
