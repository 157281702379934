import * as React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
import LandingPage from "../views/LandingPage/component";
// import HomeComponent from "../views/Home/component";
import ProductDetails from "../views/ProductDetails/component";
import Login from "../views/Login/component";
import Checkout from "../views/Checkout/component";
import Cart from "../views/Cart/component";
import ManageAddress from "../views/ManageAddress/component";
import ViewAllProduct from "../views/ViewAllProduct/component";
import UserAddress from "../views/UserAddress/component";
import PreRazorpay from "../views/Razorpay/PreRazorpay";
import Profile from "../views/Profile/component";
import BuyerProfile from "../views/BuyerProfile/component";
import Order from "../views/Order/component";
import OrderDetails from "../views/OrderDetails/component";
import NewHomePage from "../views/NewHomepage/component";

import TermsCondition from "../views/Common/Terms-condition/terms-condition";
import PrivacyPolicy from "../views/Common/Privacy-policy/privacy-policy";
import DeliveryPolicy from "../views/Common/Delivery-policy/delivery-policy";
import ReturnRefundPolicy from "../views/Common/Return-Refund-Policy/return-refund-policy";
import Disclaimer from "../views/Common/Disclaimer/disclaimer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/:slug" element={<NewHomePage />} />
        <Route path="/productDetails" element={<ProductDetails />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/manageAddress" element={<ManageAddress />} />
        <Route path="/login" element={<Login />} />
        <Route path="/viewAllProduct" element={<ViewAllProduct />} />
        <Route path="/address" element={<UserAddress />} />
        <Route path="/preRazorpay" element={<PreRazorpay />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/buyerprofile" element={<BuyerProfile />} />
        <Route path="/order" element={<Order />} />
        <Route path="/orderDetails" element={<OrderDetails />} />
        {/* <Route path="/home" element={<HomeComponent/>} /> */}

        <Route path="/termsCondition" element={<TermsCondition />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/deliveryPolicy" element={<DeliveryPolicy />} />
        <Route path="/returnRefundPolicy" element={<ReturnRefundPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
    </Router>
  );
}

export default App;
