export const GET_HOME_CATALOG = "/kikoweb/catalogues-group-by-categories";
export const GET_CATEGORIES = "/kikoweb/get-catalogue-categories";
export const GET_USER_ADDRESS = "/user/address";
export const ADD_USER_ADDRESS = "/user/address";
export const ESTIMATION = "/order/delivery-estimation";
export const REQUEST_OTP = "/request-otp-v2";
export const STORE_DETAILS = "/user/fetch-store-details";
export const LOGIN = "/kikoweb/login";
export const GET_SELLER_DETAIL = "/accounts/get-user-by-slug/";
export const CREATE_ORDER_OFFLINE = "/order/create-order-offline-v2";
export const GET_PINCODE_DETAILS = "/maps/getpicodedetails";
export const SEARCH_CATALOG = "/kikoweb/fetch-catalogues"
export const UPDATE_USER_PROFILE = "/accounts/edit"
export const GET_BUYER_ORDER = "/order/get-orders"
export const GET_ORDER_DETAILS = "/order/"


// export const GetAddressApi = body =>
//   GET(body, RESOURCE, `/user/address`)
//     .then(response => response.json())
//     .catch(Utils.handleError);

// export const GetAddressApiById = body =>
//   GET(body, RESOURCE, `/user/address?userId=${body.userId}`)
//     .then(response => response.json())
//     .catch(Utils.handleError);

// export const AddAddressApi = body =>
//   POST(RESOURCE, `/user/address`, body)
//     .then(response => response.json())
//     .catch(Utils.handleError);

// export const UpdateAddressApi = body =>
//   PUT(RESOURCE, `/user/address`, body)
//     .then(response => response.json())
//     .catch(Utils.handleError);

// export const DeleteAddressApi = body =>
//   DELETE(body, RESOURCE, `/user/address/${body.addressId}`)
//     .then(response => response.json())
//     .catch(Utils.handleError);