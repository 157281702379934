import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchCatalog } from "../../api/ApiService";
import API from "../../api";
import { GET_CATEGORIES, GET_SELLER_DETAIL } from "../../api/apiList";
import { get } from "lodash";
import Loader from "../../components/common/loader";
import { copyToClipboard } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";
import ThankYouModal from "../../components/common/ThankyouModal";
import KikoLogo from "../../images/kiko--logo.svg";
import Facebook from "../../images/facebook.svg";
import Insta from "../../images/instagram.svg";
import Youtube from "../../images/youtube.svg";
import LinkedIn from "../../images/linkedin11.svg";
import AppStore from "../../images/appstore.png";
import PlayStore from "../../images/playstore.png";
import Slider from "react-slick";
import DownArrow from "../../images/down-arrow.svg";
import CartNewIcon from "../../components/svgicons/cartnewicon";
import searchIcon from "../../images/searchIcon.svg";
import HamburgerNew from "../../components/svgicons/Hamburger";
import MenuProfile from "../../components/NewImages/menu-profile-icon.svg";
import { SEARCH_CATALOG } from "../../api/apiList";
import { Modal, ModalFooter } from "reactstrap";
import "./styles.scss";
import { USER_DATA } from "../../components/common/constant";

import LogoutLogo from "../../images/logout-logo.png";
import Login from "../Login/component";
const HomeComponent = (props) => {
  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem(USER_DATA) || "");
    } catch (error) {
      return null;
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const catalogue = useSelector((s) => s.catalog.data);
  const [catalogDataTemp, setCatalogDataTemp] = useState([]);
  let cartItems = [];
  const [cartItemLength, setCartItemLength] = useState();
  // const startLoading = useSelector((s) => s.catalog.loading);
  const [startLoading, setStartLoading] = useState(false);

  const [categoriesData, setCategoriesData] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [storeDetails, setStoreDetail] = useState({});
  const [sortType, setSortType] = useState("");
  const [openThankPopup, setOpenThankPopup] = useState(true);
  const [catalogDetailsData, setCatalogDetailsData] = useState({});
  const [popup, setPopup] = useState(false);
  const [logout, setlogout] = useState(false);
  const [name, setName] = useState(getUserFromLocalStorage()?.name);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  let sellerDetails = {};
  try {
    sellerDetails = JSON.parse(localStorage.getItem("sellerDetails"));
  } catch (error) {
    sellerDetails = null;
  }

  let { slug } = useParams();
  // console.log("SLUG : ", slug);
  localStorage.setItem("slug", "/" + slug);
  if (storeDetails) {
    cartItems =
      JSON.parse(localStorage.getItem("cart_" + storeDetails?._id)) || [];
  }

  const getCategoriesData = async () => {
    try {
      const response = await API.post(GET_CATEGORIES);
      if (response.data) {
        // console.log("HOME : DATA ", response.data)
        setCategoriesData(response.data);
      }
    } catch (error) {
      console.log("HOME : ERROR CATEGORIES ", error);
    }
  };

  const getSellerDetails = async () => {
    try {
      const response = await API.get(GET_SELLER_DETAIL + slug);
      if (response.data) {
        // console.log("HOME : SELLER DEATILS 12", response.data.user)
        cartItems =
          JSON.parse(
            localStorage.getItem("cart_" + response?.data?.user?._id)
          ) || [];
        setCartItemLength(cartItems?.length);
        setStoreDetail(response.data.user);
        localStorage.setItem(
          "sellerDetails",
          JSON.stringify(response.data.user)
        );
      }
      setStartLoading(false);
    } catch (error) {
      setStartLoading(false);
      console.log("HOME : ERROR SELLER DETAILS ", error);
    }
  };

  useEffect(() => {
    setStartLoading(false);
    catalogs();
  }, [catalogue]);

  useEffect(() => {
    if (storeDetails?._id) {
      fetchCatalog({
        category: categoryName,
        sellerId: storeDetails?._id,
        sortBy:
          sortType === "Price: Low To High"
            ? 1
            : sortType === "Price: High To Low"
            ? -1
            : 0,
      });
    }
  }, [categoryName, sortType, storeDetails]);

  useEffect(() => {
    setStartLoading(true);
    getSellerDetails();
    getCategoriesData();
  }, []);

  const catalogs = () => {
    const data = catalogue.map((catalog) => {
      return {
        ...catalog,
        catalogues: catalog.catalogues.map((data) => {
          const index = cartItems.findIndex((item) => item._id === data._id);
          if (index !== -1) {
            return { ...data, quantity: cartItems[index].quantity };
          } else {
            return { ...data, quantity: 1 };
          }
        }),
      };
    });
    setCatalogDataTemp([...data]);
  };
  const cartData = {
    _id: "",
    quantity: 0,
    catalogData: {},
  };
  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItems.findIndex((item) => item._id === itemId);
    if (newQuantity > parseInt(singleCatalogData.availableQuantity)) {
      alert(
        "Available Quantity is only " + singleCatalogData.availableQuantity
      );
    } else {
      if (itemIndex !== -1) {
        // Update the quantity of the item
        let updatedCartItems = [...cartItems];

        if (newQuantity === 0) {
          const arr = updatedCartItems.filter(function (item) {
            console.log(
              "item !== updatedCartItems[itemIndex]",
              item !== updatedCartItems[itemIndex]
            );
            return item !== updatedCartItems[itemIndex];
          });
          updatedCartItems = arr;
          console.log("itemId=-=-=-=-", itemId);
          toggleButtons(itemId);
        } else {
          updatedCartItems[itemIndex].quantity = newQuantity;
        }
        localStorage.setItem(
          "cart_" + storeDetails?._id,
          JSON.stringify(updatedCartItems)
        );
        catalogs();
        setCartItemLength(updatedCartItems?.length);
      } else {
        cartData._id = singleCatalogData._id;
        cartData.quantity = newQuantity;
        cartData.catalogData = singleCatalogData;
        cartItems.push(cartData);
        localStorage.setItem(
          "cart_" + storeDetails?._id,
          JSON.stringify(cartItems)
        );
        setCartItemLength(cartItems?.length);
      }
    }
  };
  function toggleButtons(id) {
    var addElement = document.getElementById(id);
    var addWithPlusMinusElement = document.getElementById(`ui${id}`);
    console.log("addWithPlusMinusElement: ", addWithPlusMinusElement);

    var addElementDetail = document.getElementById(id);
    var addWithPlusMinusElementDetail = document.getElementById(`ui2${id}`);
    console.log("addWithPlusMinusElementDetail", addWithPlusMinusElementDetail);
    if (addElement.style.visibility === "hidden") {
      addElement.style.visibility = "visible";
      addWithPlusMinusElement.style.visibility = "hidden";
      if (addElementDetail && addElementDetail != null) {
        addElementDetail.style.visibility = "visible";
      }
      if (
        addWithPlusMinusElementDetail &&
        addWithPlusMinusElementDetail != null
      ) {
        addWithPlusMinusElementDetail.style.visibility = "hidden";
      }
    } else if (addElementDetail.style.visibility === "hidden") {
      addElementDetail.style.visibility = "visible";
      addWithPlusMinusElementDetail.style.visibility = "hidden";
      addElement.style.visibility = "visible";
      addWithPlusMinusElement.style.visibility = "hidden";
    } else {
      addElement.style.visibility = "hidden";
      addWithPlusMinusElement.style.visibility = "visible";
      addElementDetail.style.visibility = "hidden";
      if (
        addWithPlusMinusElementDetail &&
        addWithPlusMinusElementDetail != null
      ) {
        addWithPlusMinusElementDetail.style.visibility = "visible";
      }
    }
  }

  const generateWhatsAppUrl = (phoneNumber, message) => {
    return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  };

  const handleWhatsAppButtonClick = (phoneNumber, message) => {
    const whatsappUrl = generateWhatsAppUrl(phoneNumber, message);
    var anchor = document.createElement("a");
    anchor.href = whatsappUrl;
    anchor.target = "_blank";
    anchor.click();
    // window.location.href = whatsappUrl;
  };

  const ReadMore = ({ children, textLength, isStoreDes }) => {
    // console.log("READMORE : ", textLength)
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, textLength) : text}

        {text.length >= (isStoreDes ? 220 : 18) ? (
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? "  ...Read more" : " Show less"}
          </span>
        ) : null}
      </p>
    );
  };
  const [catalogueData, setCatalogData] = useState([]);

  const onChange = (searchText) => {
    console.log("HEADER : SEARCH DATA", searchText);
    if (searchText && searchText.length > 0) {
      API.post(SEARCH_CATALOG, {
        search: searchText,
        sellerId: storeDetails?._id,
      }).then((data) => {
        console.log("HEADER : SEARCH DATA", data.data);
        if (data?.data?.data.length > 0) {
          setCatalogData(data?.data?.data);
        } else {
          setCatalogData([]);
        }
      });
    } else {
      setCatalogData([]);
    }
  };
  const userLogout = () => {
    const store = JSON.parse(localStorage.getItem("sellerDetails"));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("cart_" + store?._id);
    window.location.reload();
  };
  const storeName = get(storeDetails, "storeName", "");
  const storeNameArray = storeName.split(" ");
  const firstName = storeNameArray[0];
  const lastName =
    storeNameArray.length > 1 ? storeNameArray[storeNameArray.length - 1] : "";
  const firstInitial = firstName?.charAt(0) ?? "";
  const lastInitial = lastName?.charAt(0) ?? "";
  const storeShortName = firstInitial.toUpperCase() + lastInitial.toUpperCase();
  const token = localStorage.getItem("token") || "";

  let categoryImage =
    "https://d1yd3a2ik8ypqd.cloudfront.net/uploads/user_images/pMOcibGMG.png";
  switch (get(storeDetails, "mainCategory", "")) {
    case "Beauty & Personal Care":
      categoryImage =
        "https://d1yd3a2ik8ypqd.cloudfront.net/uploads/user_images/UJcbVukCW.png";
      break;
    case "Fashion":
      categoryImage =
        "https://d1yd3a2ik8ypqd.cloudfront.net/uploads/user_images/rA6ZFerGX.png";
      break;
    case "Food & Beverage":
      categoryImage =
        "https://d1yd3a2ik8ypqd.cloudfront.net/uploads/user_images/wCYFfnxWo.png";
      break;
    case "Grocery":
      categoryImage =
        "https://d1yd3a2ik8ypqd.cloudfront.net/uploads/user_images/_dHYvTWgF.png";
      break;
    case "Health & Wellness":
      categoryImage =
        "https://d1yd3a2ik8ypqd.cloudfront.net/uploads/user_images/DtjUughZF.png";
      break;
    default:
      categoryImage =
        "https://d1yd3a2ik8ypqd.cloudfront.net/uploads/user_images/pMOcibGMG.png";
  }
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    pauseOnHover: true,
  };
  return (
    <>
      {startLoading ? (
        <div className="dotLoader">
          <Loader />
        </div>
      ) : (
        <>
          <ToastContainer
            autoClose={300}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <div className="header-image-block">
            <div className="header-img">
              <Slider {...settings}>
                <div className="header-img">
                  <img src={categoryImage} alt="Top Banner" />
                </div>
                {/* <div className="header-img">
              <img
                src={
                  storeDetails?.storeLogo ? storeDetails?.storeLogo : kikologobg
                }
                alt="Store Logo"
              />
            </div> */}
              </Slider>
              <div className="header__icons">
                {token && token !== "" ? (
                  <div
                    className="left--icon"
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasExample"
                  >
                    <HamburgerNew />
                  </div>
                ) : (
                  <div
                    className="left--icon"
                    onClick={() => {
                      setModalIsOpen(true);
                    }}
                  >
                    <HamburgerNew />
                  </div>
                )}
                <span className="profileDetails right--icon">
                  <a href="/cart">
                    <CartNewIcon />
                  </a>
                  {cartItemLength > 0 && (
                    <div className="cart-Counter">{cartItemLength}</div>
                  )}
                </span>
              </div>
              <div className="Profile--text">
                <span className="profile-word">{storeShortName}</span>
              </div>
            </div>
          </div>
          <div className="shop__details">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-7 col-md-7">
                  <div className="shop-left">
                    <h1 className="shop-name">
                      {get(storeDetails, "storeName", "")}
                    </h1>
                    <ReadMore
                      className="product-desc"
                      textLength={220}
                      isStoreDes={true}
                    >
                      {get(storeDetails, "description", "")}
                    </ReadMore>
                    <div className="searchBar">
                      <div className="searchInput">
                        <button>
                          <img src={searchIcon} alt="Search Icon" />
                        </button>
                        <input
                          type="text"
                          placeholder="Search for a product"
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        />
                      </div>
                      {catalogueData && catalogueData?.length > 0 ? (
                        <div className="searchDropdown">
                          {catalogueData?.map((data) => {
                            return (
                              <p
                                onClick={() => {
                                  !props.func &&
                                    navigate("/productDetails", {
                                      state: {
                                        catalogData: data,
                                        catalogDataArray: {},
                                      },
                                    });
                                  props.func && props.func(data);
                                  props.func && setCatalogData([]);
                                }}
                              >
                                {data.productName}
                              </p>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5">
                  <div className="shop-right">
                    <p className="shop--address">
                      {storeDetails?.storeAddress?.address2 ??
                        "" + " " + storeDetails?.storeAddress?.state ??
                        "" + " " + storeDetails?.storeAddress?.city ??
                        "" + " " + storeDetails?.storeAddress?.pincode ??
                        ""}
                    </p>
                    <div className="d-flex gap-3 mb-3 number--min">
                      <p
                        class="contact--icon contactNo"
                        onClick={() => {
                          copyToClipboard(storeDetails?.phone);
                        }}
                      >
                        {storeDetails?.phone}
                      </p>
                      {storeDetails?.whatsAppNumber && (
                        <p
                          class="contact--icon WhatsaapNo"
                          onClick={() => {
                            handleWhatsAppButtonClick(
                              storeDetails?.whatsAppNumber,
                              "Hi I need your help"
                            );
                          }}
                        >
                          {"+91" + storeDetails?.whatsAppNumber}
                        </p>
                      )}
                    </div>
                    <div className="collectionFilter">
                      <div className="filterItem">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setCategoryName(
                              e.target.value === "All Categories"
                                ? ""
                                : e.target.value
                            );
                          }}
                        >
                          <option>All Categories</option>
                          {categoriesData.data !== undefined &&
                            categoriesData.data.map((data) => {
                              return <option>{data.title}</option>;
                            })}
                        </select>
                        <img src={DownArrow} className="downArrow" />
                      </div>
                      <div className="filterItem">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setSortType(e.target.value);
                          }}
                        >
                          <option>Sort</option>
                          <option>All Products</option>
                          <option>Price: Low To High</option>
                          <option>Price: High To Low</option>
                        </select>
                        <img src={DownArrow} className="downArrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="filtersection">
            <div className="container"></div>
          </section>
          <div className="product--Section">
            <div className="container">
              <div className="row">
                {catalogDataTemp?.length > 0 ? (
                  <div className="col-lg-7 col-md-6">
                    {catalogDataTemp.map((catalogList) => {
                      return (
                        <div className="mb-3" key={catalogList.categoryName}>
                          {/* <div className="pb-3 d-flex justify-content-between align-items-center">
                          <h1 className="categoryTitle">
                            {catalogList.categoryName}
                          </h1>
                          <p
                            onClick={() =>
                              navigate("/viewAllProduct", {
                                state: catalogList.categoryName,
                              })
                            }
                            className="view-all m-0"
                            style={{ cursor: "pointer" }}
                          >
                            View all
                          </p>
                        </div> */}

                          <div className="ProductList-grid">
                            {catalogList?.catalogues.map((data, index) => {
                              // if (index > 20) {
                              //   return null;
                              // }
                              return (
                                <div className="productCard">
                                  <div className="d-flex justify-content-between gap-3">
                                    <div
                                      className="productDetail"
                                      onClick={() =>
                                        navigate("/productDetails", {
                                          state: {
                                            catalogData: data,
                                            catalogDataArray: catalogList,
                                          },
                                        })
                                      }
                                    >
                                      <div>
                                        <h2 className="productTitle">
                                          {data.productName}
                                        </h2>
                                        <p className="productDes">
                                          {data.description?.slice(0, 90)}
                                        </p>
                                      </div>
                                      <div>
                                        <div className="productRate">
                                          <span className="line-thorugh">
                                            ₹{data.price}
                                          </span>
                                          <h1>₹{data.discountedPrice}</h1>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="productimage">
                                      <div className="productImg">
                                        <img
                                          src={data.productImages[0]}
                                          onClick={() =>
                                            navigate("/productDetails", {
                                              state: {
                                                catalogData: data,
                                                catalogDataArray: catalogList,
                                              },
                                            })
                                          }
                                          alt="Product Catalog"
                                        />
                                      </div>
                                      <div className="add--cart-btn">
                                        <button
                                          id={data._id}
                                          className="addBtn"
                                          onClick={() => {
                                            toggleButtons(data._id);
                                            updateCartItemQuantity(
                                              data._id,
                                              data.quantity,
                                              data
                                            );
                                          }}
                                        >
                                          Add
                                        </button>
                                        <div
                                          style={{ visibility: "hidden" }}
                                          id={`ui${data._id}`}
                                          className="addtocart-qty"
                                        >
                                          <div
                                            class="addtocart-button button-down minus"
                                            onClick={() => {
                                              updateCartItemQuantity(
                                                data._id,
                                                data.quantity === 1
                                                  ? 0
                                                  : (data.quantity -= 1),
                                                data
                                              );
                                            }}
                                          >
                                            -
                                          </div>
                                          <input
                                            type="number"
                                            class="addtocart-input"
                                            value={data.quantity}
                                          />
                                          <div
                                            class="addtocart-button button-up"
                                            onClick={() => {
                                              updateCartItemQuantity(
                                                data._id,
                                                (data.quantity += 1),
                                                data
                                              );
                                            }}
                                          >
                                            +
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <button
                                  className="detail-page"
                                  data-bs-toggle="modal"
                                  data-bs-target="#productDetailsModal"
                                  onClick={() => {
                                    setCatalogDetailsData(data);
                                  }}
                                >
                                  View Details{" "}
                                </button> */}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="notdatafound">
                    {/* <h1 className="no-found-data">Product not found</h1> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            class="modal fade productDetailModal view--detail-modal"
            id="productDetailsModal"
            tabindex="-1"
            aria-labelledby="productdetailsModal"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header border-0">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="product--img">
                  <Slider
                    slidesToShow={1}
                    // slidesToScroll={1} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}
                    arrows={true}
                    dots={true}
                    className="product-slider"
                  >
                    {catalogDetailsData &&
                    catalogDetailsData?.productImages?.length > 0
                      ? catalogDetailsData?.productImages.map((data) => {
                          return (
                            <div className="prodcutImage">
                              <img src={data} alt="Product Catalog" />
                            </div>
                          );
                        })
                      : null}
                  </Slider>
                  {/* <img src={catalogDetailsData?.productImages?.[0]} alt="Product" /> */}
                </div>
                <div class="modal-body">
                  <div
                    class="productDetail"
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h2 class="productTitle">
                        {catalogDetailsData?.productName?.slice(0, 15)}
                      </h2>
                    </div>
                    <div class="productRate">
                      <span class="line-thorugh">
                        ₹{catalogDetailsData?.price}
                      </span>
                      <h1>₹{catalogDetailsData?.discountedPrice}</h1>
                    </div>
                  </div>
                  <h6>Product Discription</h6>
                  <p>{catalogDetailsData?.description}</p>
                </div>
                <div class="modal-footer border-0">
                  <div style={{ position: "relative", width: "100%" }}>
                    <button
                      id={catalogDetailsData._id}
                      className="btn Dark-btn w-100"
                      style={{ maxWidth: "100%" }}
                      onClick={() => {
                        toggleButtons(catalogDetailsData._id);
                        updateCartItemQuantity(
                          catalogDetailsData._id,
                          catalogDetailsData.quantity,
                          catalogDetailsData
                        );
                      }}
                    >
                      Add
                    </button>
                    <div
                      style={{ visibility: "hidden", maxWidth: "100%" }}
                      id={`ui2${catalogDetailsData._id}`}
                      className="addtocart-qty"
                    >
                      <div
                        class="addtocart-button button-down minus"
                        onClick={() => {
                          updateCartItemQuantity(
                            catalogDetailsData._id,
                            catalogDetailsData.quantity === 1
                              ? 0
                              : (catalogDetailsData.quantity -= 1),
                            catalogDetailsData
                          );
                        }}
                      >
                        -
                      </div>
                      <input
                        type="number"
                        value={catalogDetailsData.quantity}
                        class="addtocart-input"
                      />
                      <div
                        class="addtocart-button button-up"
                        onClick={() => {
                          updateCartItemQuantity(
                            catalogDetailsData._id,
                            (catalogDetailsData.quantity += 1),
                            catalogDetailsData
                          );
                        }}
                      >
                        +
                      </div>
                    </div>
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="page-footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="footer-widget-area">
                    <div className="widget widget_text">
                      <div className="textwidget">
                        <div className="kiko-logo">
                          <img src={KikoLogo} />
                        </div>
                        <p style={{ color: "#ffffffbf" }}>
                          614, Avior Corporate Park, LBS Road, Mulund (W),
                          Mumbai 400080,
                          <br />
                          Maharashtra, India.
                        </p>
                        <p>
                          <a href="mailto:support@kiko.media">
                            support@kiko.media
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="widget widget_text">
                      <div className="textwidget">
                        <ul>
                          <li>
                            <a
                              href="https://facebook.com/profile.php/?id=100090104332961"
                              target="_blank"
                              rel="noreferrer"
                              className="socialIcon"
                            >
                              <img src={Facebook} alt="Facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/kikoliveapp/"
                              target="_blank"
                              rel="noreferrer"
                              className="socialIcon"
                            >
                              <img src={Insta} alt="Instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://in.linkedin.com/company/kikolive"
                              target="_blank"
                              rel="noreferrer"
                              className="socialIcon"
                            >
                              <img src={LinkedIn} alt="LinkedIn" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/@kikolive5631"
                              target="_blank"
                              rel="noreferrer"
                              className="socialIcon"
                            >
                              <img src={Youtube} alt="LinkedIn" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="footer-widget-area">
                    <div className="menu-footer-container">
                      <ul className="menu">
                        {/* <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://kiko.live/faq/"
                            className="menu-link"
                          >
                            FAQs
                          </a>
                        </li> */}
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://kiko.live/disclaimer/"
                            className="menu-link"
                          >
                            Disclaimer
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://kiko.live/privacy-policy/"
                            className="menu-link"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://kiko.live/delivery-policy/"
                            className="menu-link"
                          >
                            Delivery Policy
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://kiko.live/return-refund-policy/"
                            className="menu-link"
                          >
                            Return & Refund Policy
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://kiko.live/terms-conditions/"
                            className="menu-link"
                          >
                            Terms & Conditions
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="downloadAppButtons">
                      <div className="AppButtons">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://apps.apple.com/in/app/kiko-live/id1567183166"
                          className="downloadLink"
                        >
                          <img src={AppStore} alt="App Store" />
                        </a>
                      </div>
                      <div className="AppButtons">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://play.google.com/store/apps/details?id=live.kiko.user&pcampaignid=web_share"
                          className="downloadLink"
                        >
                          <img src={PlayStore} alt="Play Store" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <section className="copyright-block">
            <div className="container">
              <p>Kiko © All Rights Reserved - 2023</p>
            </div>
          </section>
          {location.state?.paymentSuccessful && (
            <ThankYouModal
              orderAmount={location.state.orderAmount}
              openModal={openThankPopup}
              setOpenModal={setOpenThankPopup}
            />
          )}
          <Modal
            isOpen={popup}
            toggle={() => {
              setPopup(false);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ maxWidth: "600px" }}
          >
            <div className="addressmodalBlock">
              <h2>Title</h2>
              <p>Description</p>
              <div className="d-flex justify-content-end">
                <button className="btn Dark-btn">OK</button>
              </div>
            </div>
          </Modal>
          <div
            class="offcanvas offcanvas-start side-hamburger"
            tabindex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div class="offcanvas-header justify-content-end">
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <div className="menu-title">
                <h1>
                  <img src={MenuProfile} className="me-2" />
                  {name}
                </h1>
              </div>
              <ul className="menu--list">
                <li>
                  <a class="Menu--item" href="/buyerprofile">
                    Profile Information
                  </a>
                </li>
                <li>
                  <a class="Menu--item" href="/manageAddress">
                    Manage Address
                  </a>
                </li>
                <li>
                  <a class="Menu--item" href="/order">
                    Order
                  </a>
                </li>
              </ul>
              <div className="logout_menu" data-bs-dismiss="offcanvas">
                <div
                  className="menu-logout-list"
                  onClick={() => {
                    setlogout(true);
                  }}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={logout}
            aria-labelledby="contained-modal-title-vcenter"
            toggle={() => {
              setlogout(false);
            }}
            centered
            className="clearcartmodal"
          >
            <div className="confirmLogout">
              <img src={LogoutLogo} alt="Logout" />
              <h1 className="confirm-subtitle">
                Thank you for visiting {sellerDetails?.storeName}
              </h1>
            </div>
            <ModalFooter className="border-0 flex-column">
              <h5 className="confirmtitle">Are you sure you want to logout?</h5>
              <div className="d-flex justify-content-center gap-2">
                <button
                  onClick={() => {
                    setlogout(false);
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    userLogout();
                  }}
                >
                  Yes
                </button>
              </div>
            </ModalFooter>
          </Modal>
          {modalIsOpen && (
            <Login
              isOpenFromCart={false}
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
            ></Login>
          )}
        </>
      )}
    </>
  );
};
export default HomeComponent;
