import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/component";
import LeftArrow from "../../components/svgicons/leftArrow";
import Loader from "../../components/common/loader";
import { useSelector } from "react-redux";
import { fetchCatalog } from "../../api/ApiService";
import { sellerDetails } from "../../components/common/utils";

const ViewAllProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false)

    const categoryName = location.state

    const catalogue = useSelector(s => s.catalog.data)
    const [catalogDataTemp, setCatalogDataTemp] = useState([])
    let cartItems = JSON.parse(localStorage.getItem("cart_" + sellerDetails()?._id)) || [];
    const [cartItemLength, setCartItemLength] = useState(cartItems?.length)


    useEffect(() => {
        catalogs();
    }, [catalogue]);


    useEffect(() => {

        fetchCatalog({
            category: categoryName,
            sellerId: sellerDetails()?._id
        })
    }, [categoryName]);


    const catalogs = () => {
        const data = catalogue.map((catalog) => {
            return {
                ...catalog,
                catalogues: catalog.catalogues.map((data) => {
                    const index = cartItems.findIndex((item) => item._id === data._id);
                    if (index !== -1) {
                        return { ...data, quantity: cartItems[index].quantity };
                    } else {
                        return { ...data, quantity: 1 };
                    }
                })
            };
        })
        setCatalogDataTemp([...data])
    }
    const cartData = {
        _id: "",
        quantity: 0,
        catalogData: {}
    }

    const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
        const itemIndex = cartItems.findIndex(item => item._id === itemId);
        if (newQuantity > parseInt(singleCatalogData.availableQuantity)) {
            alert("Available Quantity is only " + singleCatalogData.availableQuantity)
        } else {
            if (itemIndex !== -1) {
                // Update the quantity of the item
                let updatedCartItems = [...cartItems];
                if (newQuantity === 0) {
                    const arr = updatedCartItems.filter(function (item) {
                        return item !== updatedCartItems[itemIndex]
                    })
                    updatedCartItems = arr
                    toggleButtons(itemId)
                } else {
                    updatedCartItems[itemIndex].quantity = newQuantity;
                }
                localStorage.setItem("cart_" + sellerDetails()?._id, JSON.stringify(updatedCartItems));
                catalogs();
                setCartItemLength(updatedCartItems?.length)
            } else {
                cartData._id = singleCatalogData._id
                cartData.quantity = newQuantity
                cartData.catalogData = singleCatalogData
                cartItems.push(cartData);
                localStorage.setItem("cart_" + sellerDetails()?._id, JSON.stringify(cartItems));
                setCartItemLength(cartItems?.length)
            }
        }
    }

    function toggleButtons(id) {
        var addElement = document.getElementById(id);
        var addWithPlusMinusElement = document.getElementById(`ui${id}`);

        if (addElement.style.visibility === 'hidden') {
            addElement.style.visibility = 'visible';
            addWithPlusMinusElement.style.visibility = 'hidden';
        } else {
            addElement.style.visibility = 'hidden';
            addWithPlusMinusElement.style.visibility = 'visible';
        }
    }
    return (<>
        <Header cartItemLength={cartItemLength} />
        <div className="productSection">
            <div className="container">
                <>
                    <div className="dotLoader">
                        {loading && <Loader />}
                    </div>
                    {catalogDataTemp && catalogDataTemp.length > 0 ? catalogDataTemp.map((catalogList) => {
                        return (
                            <div key={catalogList.categoryName}>
                                <div className="d-flex gap-2">
                                    <h1 className="categoryTitle pb-3">  <span   onClick={() => {navigate(localStorage.getItem("slug"));}} style={{ color: "#000", textDecoration: "none" }}><LeftArrow /> </span>{catalogList.categoryName}</h1>
                                </div>
                                <div className="ProductList-grid">
                                    {catalogList?.catalogues.map((data, index) => {
                                        return (

                                            <div className="productCard">
                                                <div className="d-flex justify-content-between gap-3">
                                                    <div className="productDetail" onClick={() => navigate("/productDetails", { state: { catalogData: data, catalogDataArray: catalogList } })}>
                                                        <div>
                                                            <h2 className="productTitle">{data.productName}</h2>
                                                            <p className="productDes">{data.description.slice(0, 170)}</p></div>
                                                        <div>
                                                            <div className="productRate">
                                                                <h1>₹{data.discountedPrice}</h1>
                                                                <span className="line-thorugh">₹{data.price}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="productimage">
                                                        <div className="productImg">
                                                            <img src={data.productImages[0]} alt="Product Catalog" />
                                                        </div>
                                                        <div style={{ position: "relative", textAlign: "end" }}>
                                                            <button id={data._id} className="addBtn" onClick={() => {
                                                                toggleButtons(data._id); updateCartItemQuantity(data._id, data.quantity, data)
                                                            }}>Add to cart</button>
                                                            <div style={{ visibility: "hidden" }} id={`ui${data._id}`} className="addtocart-qty">
                                                                <div class="addtocart-button button-down minus" onClick={() => { updateCartItemQuantity(data._id, data.quantity === 1 ? 0 : data.quantity -= 1, data) }}>-</div>
                                                                <input type="number" class="addtocart-input" value={data.quantity} />
                                                                <div class="addtocart-button button-up" onClick={() => { updateCartItemQuantity(data._id, data.quantity += 1, data) }}>+
                                                                </div>
                                                            </div>
                                                            <div />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }) : <div className="notfounderror"><h1 className="no-found-data">Product not found</h1></div>}
                </ >
            </div>
        </div>
    </>);
}
export default ViewAllProduct