import React, {useState} from "react";
import Header from "../Header/component";
import LeftArrow from "../../components/svgicons/leftArrow";
import API from "../../api";
import {UPDATE_USER_PROFILE} from "../../api/apiList";
import {
  handleError,
  notify,
  sellerDetails,
} from "../../components/common/utils";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {USER_DATA} from "../../components/common/constant";

const BuyerProfile = () => {
  const navigate = useNavigate();

  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem(USER_DATA) || "");
    } catch (error) {
      return null;
    }
  };
  const [name, setName] = useState(getUserFromLocalStorage()?.name);
  const [email, setEmail] = useState(getUserFromLocalStorage()?.email);
  const [isValidEmail, setValidEmail] = useState(true);

  let cartItems = [];
  try {
    cartItems = JSON.parse(
      localStorage.getItem("cart_" + sellerDetails()?._id)
    );
  } catch {
    cartItems = null;
  }

  // const [cartItemLength, setCartItemLength] = useState(cartItems?.length)
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  function updateUserProfile() {
    const body = {
      userId: getUserFromLocalStorage()?.userId,
      token:
        getUserFromLocalStorage() && getUserFromLocalStorage()?.token
          ? getUserFromLocalStorage()?.token
          : "",
    };
    if (name !== "") {
      body["name"] = name;
    }
    if (email !== "") {
      if (isEmail(email)) {
        setValidEmail(true);
        body["email"] = email;
      } else {
        setValidEmail(false);
        return;
      }
    } else {
      setValidEmail(true);
    }
    console.log("UPDATE USER: PROFILE ", body);
    API.post(UPDATE_USER_PROFILE, body)
      .then(({data}) => {
        console.log("UPDATE USER: PROFILE 12", data);
        if (data) {
          // setEstimationData(get(data, 'data', {}))
          // setKikoDelivery(get(data, 'data.kikoDelivery', false))
          // console.log("UPDATE USER: PROFILE 11", data.data);
          localStorage.setItem(USER_DATA, JSON.stringify(data.user));
          notify("success", data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  return (
    <>
      <Header cartItemLength={cartItems?.length} />
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="productSection">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <p className="m-0 pt-3" style={{cursor: "pointer"}}>
                <LeftArrow
                  className="me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                Back
              </p>
              <div className="d-flex justify-content-between align-items-center py-3">
                <h4 className="cartView-address-type">Personal Information</h4>
                {/* <p className="editText">Edit</p> */}
              </div>
              <form className="buyerDetails">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Email Id (Optional)</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Id"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setValidEmail(
                          e.target.value === "" || isEmail(e.target.value)
                        );
                      }}
                    />
                    {!isValidEmail && <div>Enter valid email</div>}
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Mobile Number</label>
                    <input
                      disabled={true}
                      type="number"
                      className="form-control"
                      value={getUserFromLocalStorage()?.mobile}
                    />
                  </div>
                  <div className="col-lg-12 mb-3 text-center">
                    <button
                      type="button"
                      className="btn Dark-btn w-50"
                      onClick={() => updateUserProfile()}
                    >
                      Update Profile
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BuyerProfile;
