import React from "react";
import KikoLogo from "../../../images/kiko--logo.svg";
import Facebook from "../../../images/facebook.svg";
import Insta from "../../../images/instagram.svg";
import Youtube from "../../../images/youtube.svg";
import LinkedIn from "../../../images/linkedin11.svg";
import AppStore from "../../../images/appstore.png";
import PlayStore from "../../../images/playstore.png";
import "./styles.scss"

const TermsCondition = (props) => {
    return (
        <>
            <header className="page-header">
                <div className="container">
                    <span className="ltx-before"></span>
                    <h1>Terms &amp; Conditions</h1><ul className="breadcrumbs" typeof="BreadcrumbList" vocab="https://schema.org/">
                        <li className="home"><span property="itemListElement" typeof="ListItem"><a property="item" typeof="WebPage" title="Go to Home." href="https://kiko.live" className="home"><span property="name">Home</span></a><meta property="position" content="1" /></span></li>
                        <li className="post post-page current-item"><span property="itemListElement" typeof="ListItem"><span property="name">Terms &amp; Conditions</span><meta property="position" content="2" /></span></li>
                    </ul>
                    <span className="ltx-after"></span>
                    <div className="ltx-header-icon"></div>

                </div>
            </header>
            <div className="container main-wrapper">

                <div className="inner-page text-page margin-default">
                    <div className="row justify-content-center">
                        <div className="col-xl-9 col-lg-8 col-md-12 col-xs-12 text-page">
                            <article id="post-9920" className="post-9920 page type-page status-publish hentry">
                                <div className="entry-content clearfix" id="entry-div">

                                    <p>Welcome to Kiko Live. Kiko Live and its affiliates provide access to the Kiko Live app (the “app”) to you subject to the conditions set out on this page.</p>
                                    <p><strong>Conditions of Use</strong></p>
                                    <p><strong>Conditions of Sale</strong></p>
                                    <p>Please read these conditions carefully before using the Kiko Live app. By using the Kiko Live app, you signify your agreement to be bound by these conditions.</p>
                                    <p>Conditions Relating to Your Use of the Kiko Live app</p>
                                    <ol>
                                        <li><strong> YOUR ACCOUNT</strong></li>
                                    </ol>
                                    <p>If you use the app, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer to prevent unauthorised access to your account. You agree to accept responsibility for all activities that occur under your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner. Please ensure that the details you provide us with are correct and complete and inform us immediately of any changes to the information that you provided when registering. You can access and update much of the information you provided us within the Edit Account area of the app. Kiko Live app reserves the right to refuse access to the app, terminate accounts, remove or edit content at any time without notice to you.</p>
                                    <ol start="2">
                                        <li><strong> PRIVACY</strong></li>
                                    </ol>
                                    <p>Please review our Privacy Policy, which also governs your visit to the Kiko Live app, to understand our practices. The personal information/data provided to us by you during the course of usage of the Kiko Live app will be treated as confidential and we take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional misconduct will be the standards used to measure our compliance with that duty. If you object to your information being transferred or used, please do not use the app.</p>
                                    <p>The rules and regulations, privacy policy or user agreement of any intermediary shall inform the user of its computer resource not to host, display, upload, modify, publish, transmit, store, update or share any information that-</p>
                                    <ol>
                                        <li>belongs to another person and to which the user does not have any right;</li>
                                        <li>is defamatory, obscene, pornographic, paedophilic, invasive of another‘s privacy, including bodily privacy, insulting or harassing on the basis of gender, libellous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;</li>
                                        <li>is harmful to child;</li>
                                        <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
                                        <li>violates any law for the time being in force;</li>
                                        <li>deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;</li>
                                        <li>impersonates another person;</li>
                                        <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation;</li>
                                        <li>contains software virus or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                                        <li>is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injuryto any person;</li>
                                    </ol>
                                    <ol start="3">
                                        <li><strong> E-PLATFORM FOR COMMUNICATION</strong></li>
                                    </ol>
                                    <p>You agree, understand and acknowledge that the app is an online platform that enables you to connect with the seller and purchase products shown on video call with the price communicated. You further agree and acknowledge that Kiko Live is only a facilitator and is not and cannot be a party to or control in any manner any transactions on the app. Accordingly, the contract of sale of products on the app shall be a strictly bipartite contract between you and the sellers on Kiko Live app.</p>
                                    <ol start="4">
                                        <li><strong> ACCESS TO KIKO LIVE APP</strong></li>
                                    </ol>
                                    <p>We will do our utmost to ensure that availability of the app will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, your access to the app may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.</p>
                                    <ol start="5">
                                        <li><strong> YOUR CONDUCT</strong></li>
                                    </ol>
                                    <p>You must not use the app in any way that causes, or is likely to cause, the app or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not Kiko Live app, are responsible for all electronic communications and content sent from your mobile to us and you must use the app for lawful purposes only. You must not use the app for any of the following:</p>
                                    <p>for fraudulent purposes, or in connection with a criminal offense or other unlawful activity</p>
                                    <p>to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any “spam</p>
                                    <p>to cause annoyance, inconvenience or needless anxiety.</p>
                                    <ol start="6">
                                        <li><strong> REVIEWS, COMMENTS, COMMUNICATIONS AND OTHER CONTENT</strong></li>
                                    </ol>
                                    <p>Users of this app may post reviews, comments and other content; send communications; and submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of “spam.” In the event a user uses a false e-mail address, impersonates any person or entity, or otherwise misleads as to the origin of any content. Kiko Live app reserves the right (but not the obligation) to remove, refuse, delete or edit any content that in the sole judgement of Kiko Live app violates these Conditions of use and, or terminate your permission to access or use this app. </p>
                                    <p>If you do post content or submit material, and unless we indicate otherwise, you</p>
                                    <p>You represent and warrant that you own or otherwise control all of the rights to the content that you post or that you otherwise provide on or through the app; that, as at the date that the content or material is submitted to Kiko Live app: (i) the content and material is accurate; (ii) use of the content and material you supply does not breach any applicable Kiko Live app policies or guidelines and will not cause injury to any person or entity (including that the content or material is not defamatory); (iii) the content is lawful. You agree to indemnify Smooth Tag Technologies Private Limited and its affiliates for all claims brought by a third party against it or its affiliates arising out of or in connection with a breach of any of these warranties.</p>
                                    <p><strong>7.KIKO SERVICES</strong></p>
                                    <ol>
                                        <li>Kiko will give you a groundbreaking experience with extraordinary features.</li>
                                        <li>Live shopping- Watch live product video and shop</li>
                                        <li>One-on-One Call – Get assistance from expert salesmen while shopping via video call.</li>
                                        <li>Product giveaway- Get amazing products from contest and giveaways</li>
                                    </ol>
                                    <ol start="8">
                                        <li><strong> RETURN AND REFUND POLICY</strong></li>
                                    </ol>
                                    <p>Thanks for shopping at Kiko Live. As agreed by you, Kiko Live enables a registered user of the Kiko Live App to connect with independent service providers (“Kiko Live Seller”), to fulfil tasks raised by such users. Such tasks include, order placing by user to particular seller and delivery of that order. We provide no guarantee and warranty on any product sold by the seller. For any such concerns, kindly contact the seller, Kiko live doesn’t take part in this process.</p>
                                    <p>You understand and agree that for delivery of your order, we use third party services for picking up your order from the respective seller to drop off the order at your doorstep. Once delivery partner is assigned, contact the seller in case of cancellation and you may be charged cancellation fee regarding the same depending on respective sellers. </p>
                                    <p>As with any shopping experience, there are terms and conditions that apply to transactions at Kiko Live. We’ll be as brief as our attorneys will allow. The main thing to remember is that by placing an order or making a purchase at Kiko Live, you agree to the terms along with Kiko Live’s” Privacy Policy.</p>
                                    <p>Kiko serves as a platform to connect buyers with sellers and a store discovery platform. We provide a listing platform where stores can list themselves and be available for video calls. Buyers can connect with sellers on a video call and transact with them over the call. Sellers can ship goods to buyers using their own delivery service and also charge customers directly. Delivery service and payment gateway are offered to sellers as an optional service.</p>
                                    <p>Kiko does not charge any commission to sellers, nor does it list their products or generate invoices. Kiko only provides a listing to stores and buyers can search for stores and connect with them and transact with them directly.</p>
                                    <ol start="9">
                                        <li><strong> DELIVERY SERVICE POLICY</strong></li>
                                    </ol>
                                    <p>KIKO LIVE partners with third party logistic service providers (“Logistic Partners”) in order to effectuate Product delivery to Users from the sellers. This is an optional service offered to sellers, who remain responsible to ensure delivery to the buyers. Details of the Logistic Partner who will be processing the delivery of the purchased Product(s) will be provided to the User upon the purchased Product(s) being handed over to the Logistic Partner by Sellers partnered with KIKO LIVE. The User will also be provided with an approximate time of delivery of the purchased Product on the order confirmation page. Sellers partnered with KIKO LIVE may also effectuate Product delivery to Users on its own without engaging with our delivery partner. Responsibility of delivery remains the responsibility of the seller even if he chooses to use Kiko logistics partners. For any issue with delivery, buyers need to contact the seller directly.</p>
                                    <p>Users are required to peruse and understand the terms of this Delivery Policy. If you do not agree to the terms contained in the Delivery Policy, you are advised not to accept the Terms of Use and the Delivery Policy and may forthwith leave and stop using the Platforms. The terms contained in this Delivery Policy shall be accepted without modification and accordingly, you agree to be bound by the terms contained herein.</p>
                                    <ol start="10">
                                        <li><strong> CLAIMS AGAINST OBJECTIONABLE CONTENT</strong></li>
                                    </ol>
                                    <p>Because Kiko Live app lists many other users on the app and hosts many thousands of comments, it is not possible for us to be aware of the contents of each content posted, or each comment or review that is displayed. Accordingly, Kiko Live app operates on a “notice and takedown” basis. If you believe that any content on the app is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, (” Objectionable Content “), please notify us immediately by flagging the content for Making Claims of Right Infringements. Once this procedure has been followed, Kiko Live app will make all reasonable endeavours to remove such Objectionable Content complained about within a reasonable time.</p>
                                    <ol start="11">
                                        <li><strong> COPYRIGHT, AUTHORS’ RIGHTS AND DATABASE RIGHTS</strong></li>
                                    </ol>
                                    <p>All content included on the app, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Smooth Tag Technologies Private Limited, its affiliates or its content suppliers and is protected by India and international copyright, authors’ rights and database right laws. The compilation of all content on this app is the exclusive property of Smooth Tag Technologies Private Limited and its affiliates and is protected by laws of India and international copyright and database right laws. All software used on this app is the property of Smooth Tag Technologies Private Limited, its affiliates or its software suppliers and is protected by India and international copyright and author’ rights laws.</p>
                                    <p>You may not systematically extract/ or re-utilise parts of the contents of the app without Smooth Tag Technologies Private Limited and / or its affiliate’s (as may be applicable) express written consent. In particular, you may not utilise any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of this app, without Smooth Tag Technologies Private Limited and / or its affiliate’s (as may be applicable) express written consent. You may also not create and/ or publish your own database that features substantial (eg: prices and product listings) parts of this app without Smooth Tag Technologies Private Limited and / or its affiliate’s (as may be applicable) express written consent.</p>
                                    <ol start="12">
                                        <li><strong> INTELLECTUAL PROPERTY CLAIMS</strong></li>
                                    </ol>
                                    <p>Smooth Tag Technologies Private Limited and its affiliates respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please contact us for Making Claims of Right Infringements.</p>
                                    <ol start="13">
                                        <li><strong> DISCLAIMER</strong></li>
                                    </ol>
                                    <p>You acknowledge and undertake that you are accessing the services on the app and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the app. We shall neither be liable nor responsible for any actions or inactions of sellers nor any breach of conditions, representations or warranties by the sellers or manufacturers of the products and hereby expressly disclaim and any all responsibility and liability in that regard. We shall not mediate or resolve any dispute or disagreement between you and the sellers or manufacturers of the products.</p>
                                    <p>We further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products listed or displayed or transacted or the content on the app. While we have taken precautions to avoid inaccuracies in content, this app, all content, information, software, products, services and related graphics are provided as is, without warranty of any kind. We do not implicitly or explicitly support or endorse the sale or purchase of any products on the app. At no time shall any right, title or interest in the products sold through or displayed on the app vest with KIKO LIVE nor shall KIKO LIVE have any obligations or liabilities in respect of any transactions on the app.<br />In app Coins collected by users is not a legal currency and has no equivalent cash value. We may give bonus coins to any user at any time, and also reserve the right to reverse or block coin collection for any user at our discretion.<br />Using collected coins to claim prizes are entirely at our discretion. We reserve the right to decide the quantity of prizes and the coins required for every prize at any time. Having a coin balance does not guarantee a prize redemption.</p>
                                    <ol start="14">
                                        <li><strong> INDEMNITY AND RELEASE</strong></li>
                                    </ol>
                                    <p>You shall indemnify and hold harmless Smooth Tag Technologies Private Limited, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney’s fees, made by any third party or penalty imposed due to or arising out of your breach of these Conditions of Use or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.</p>
                                    <p>You hereby expressly release Smooth Tag Technologies Private Limited and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the vendors and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise.</p>
                                    <ol start="15">
                                        <li><strong> CHILDREN</strong></li>
                                    </ol>
                                    <p>Use of Kiko Live app is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may use Kiko Live app only with the involvement of a parent or guardian.</p>
                                    <ol start="16">
                                        <li><strong> OTHER BUSINESSES</strong></li>
                                    </ol>
                                    <p>Parties other than Smooth Tag Technologies Private Limited and its affiliates may operate stores, provide services, or sell product lines on Kiko Live app. For example, businesses and individuals offer products via One-on-One call. In addition, we provide links to the pages of affiliated companies and certain other businesses. We are not responsible for examining or evaluating, and we do not warrant or endorse the offerings of any of these businesses or individuals, or the content of their pages. Smooth Tag Technologies Private Limited does not assume any responsibility or liability for the actions, products, and content of any of these and any other third-parties. You can tell when a third-party is involved in your transactions, and we may share customer information related to those transactions with that third-party. You should carefully review their privacy statements and other conditions of use.</p>
                                    <ol start="17">
                                        <li><strong> COMMUNICATIONS</strong></li>
                                    </ol>
                                    <p>When you visit the Kiko Live app, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by email, SMS, phone call or by posting notices on the app or by any other mode of communication. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the app and/or your order placed on the app.</p>
                                    <ol start="18">
                                        <li><strong> LOSSES</strong></li>
                                    </ol>
                                    <p>We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced using the app.</p>
                                    <ol start="19">
                                        <li><strong> ALTERATION OF SERVICE OR AMENDMENTS TO THE CONDITIONS</strong></li>
                                    </ol>
                                    <p>We reserve the right to make changes to our app, policies, and these Conditions of Use at any time. You will be subject to the policies and Conditions of Use in force at the time that you use the app or that you order goods through our platform, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition. We reserve the right to decide who can use the app and our services, and who may not be allowed to use the app and services at our discretion.</p>
                                    <ol start="20">
                                        <li><strong> EVENTS BEYOND OUR REASONABLE CONTROL</strong></li>
                                    </ol>
                                    <p>We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.</p>
                                    <ol start="21">
                                        <li><strong> WAIVER</strong></li>
                                    </ol>
                                    <p>If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.</p>
                                    <ol start="22">
                                        <li><strong> GOVERNING LAW AND JURISDICTION</strong></li>
                                    </ol>
                                    <p>These conditions are governed by and construed in accordance with the laws of India. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Delhi.</p>
                                    <ol start="23">
                                        <li><strong> OUR DETAILS</strong></li>
                                    </ol>
                                    <p>This app is operated by Smooth Tag Technologies Private Limited.</p>
                                    <p>For the Kiko Live app, you could contact us by visiting: www.kiko.media</p>
                                    <ol start="24">
                                        <li><strong> USE OF THIRD PARTY SERVICES</strong></li>
                                    </ol>
                                    <p>When you use the Amazon Software, you may also be using the services of one or more third parties, such as a wireless carrier or a mobile platform provider. Your use of these third party services may be subject to the separate policies, terms of use, and fees of these third parties.</p>
                                    <ol start="25">
                                        <li><strong> NO REVERSE ENGINEERING</strong></li>
                                    </ol>
                                    <p>You may not, and you will not encourage, assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, or otherwise tamper with, the KIKO Software, whether in whole or in part, or create any derivative works from or of the Kiko Software.</p>
                                    <ol start="26">
                                        <li><strong> UPDATES</strong></li>
                                    </ol>
                                    <p>In order to keep the Kiko Software up-to-date, we may offer automatic or manual updates at any time and without notice to you.</p>
                                    <ol start="27">
                                        <li><strong> REWARDS</strong></li>
                                    </ol>
                                    <p>Daily coin earning limit, Users can earn coins daily as per below:</p>
                                    <ul>
                                        <li>Login/Signup: Bonus coins will be issued as per offer at any given time. Bonus coins can be reversed at any time if users are found to violate policies.</li>
                                        <li>Bonus coins are issued once per user. A single user should not create multiple accounts to claim bonus coins. If misuse of claiming bonus coins is detected by creating multiple accounts, Kiko reserves the right to reverse any bonus coin issue and block the users account. Redemption using bonus coins can be done only once per user ID and per unique address.</li>
                                        <li>Video View: 1 coin per view, subject to 50 coins maximum per day</li>
                                        <li>Like Video: 1 coin per like subject to 50 coins maximum per day</li>
                                        <li>Video Share: 1 coin pee share, subject to 25 coins maximum per day</li>
                                        <li>Live stream View: 1 coin per minute of view subject to 30 coins maximum per day<br /><strong><em>(Note: Our system uses AI to detect geniuine viewership which may include screen brightness changes and natural expected screen touches. Any coins earned due to misuse of Live viewing without actual viewing can result in reversal of the coins. Multiple violations can result in account being blocked.)</em></strong></li>
                                        <li>Event Share: 1 coin per share, subject to 25 coins maximum per day.</li>
                                        <li>Follow: 1 coin per follow subject to 25 coins maximum per day.</li>
                                        <li>Other coins earned: We may make other coin earning offers from time to time and the same may have daily limits.</li>
                                    </ul>
                                    <p>Any attempt to circumvent our systems by any means to knowingly collect more rewards than officially allowed will be deemed a violation of our fair usage policy, and we reserve the right to block such users and accounts.No rewards using Fraudulent collection of coins will be honoured.Our systems use advance AI technology to detect system misuse and we urge users not to try to exploit our systems. Any misuse of features done intentionally is equivalent to cyber fraud.In case a user finds vulnerabilities in the system we request you to report to us by sending an email to support@kiko.media and all genuine bug reports will be rewarded.</p>
                                    <h2 className="condition-of-sales"><strong>CONDITIONS OF SALE (BETWEEN SELLERS AND THE CUSTOMER)</strong></h2>
                                    <p>Please read these conditions carefully before placing an order for any products with the Sellers (“We” or “Our” or “Us”, wherever applicable) on the Kiko Live app (the app). These conditions signify your agreement to be bound by these conditions.</p>
                                    <p>In addition, when you use any current or future Kiko Live app service (eg: Wishlist or Marketplace or KIKO LIVE Service), you will also be subject to the terms, guidelines and conditions applicable to that service (“Terms”). If these Conditions of Sale are inconsistent with such Terms, the Terms will control.</p>
                                    <ol>
                                        <li><strong> Conditions Relating to the Sale of Products to You</strong></li>
                                    </ol>
                                    <p>This section deals with conditions relating to the sale of products through the app by us to you.</p>
                                    <ol start="2">
                                        <li><strong> Our Contract</strong></li>
                                    </ol>
                                    <p>When you place an order to purchase a product from the seller, the seller may ship it to you using his own staff or by using Kiko delivery service. He may use the Kiko payment gateway to bill you, or collect payment directly. Kiko only provides a platform to connect the buyer to the seller. The transaction is effected directly between the buyer and the seller and the seller is responsible for any returns or exchanges or support of the product based on their own store policies. </p>
                                    <p>Your contract is with the Sellers and you confirm that the product(s) ordered by you are purchased for your internal / personal purpose. You authorize us to declare and provide declaration to any governmental authority on your behalf stating the aforesaid purpose of the products ordered by you on the app.</p>
                                    <ol start="3">
                                        <li><strong> Returns</strong></li>
                                    </ol>
                                    <p>Please review our Returns Policy, which applies to products sold through our platform. Returns need to be done directly with the sellers based on their individual policies. Kiko only assists buyers to connect to sellers, and all transactions are directly between the seller and buyer. Kiko does not charge a commission to sellers and does not operate as a marketplace. Kiko operates as a video calling platform to connect buyers to sellers. </p>
                                    <ol start="4">
                                        <li><strong> Taxes</strong></li>
                                    </ol>
                                    <p>You shall be responsible for payment of all fees/costs/charges associated with the purchase of products from the seller and you agree to bear any and all applicable taxes including but not limited to VAT/CST, service tax, GST, duties and cesses etc.</p>
                                    <ol start="5">
                                        <li><strong> Health &amp; Safety</strong></li>
                                    </ol>
                                    <p>By purchasing any product, you acknowledge that you have read and understood our Health &amp; Safety Guidelines.</p>
                                    <ol start="6">
                                        <li><strong> Children</strong></li>
                                    </ol>
                                    <p>Use of Kiko Live app is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may purchase only with the involvement of a parent or guardian.</p>
                                    <ol start="7">
                                        <li><strong> Communications</strong></li>
                                    </ol>
                                    <p>When you visit Kiko Live app, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the app or by any other mode of communication. For contractual purposes, you consent to receive communications including SMS, e-mails or phone calls from us with respect to your order.</p>
                                    <ol start="8">
                                        <li><strong> Losses</strong></li>
                                    </ol>
                                    <p>We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when a contract for the sale of goods by us to you was formed.</p>
                                    <ol start="9">
                                        <li><strong> Alteration or Amendments to the Conditions</strong></li>
                                    </ol>
                                    <p>We reserve the right to make changes to our policies, and these Conditions of Sale at any time. You will be subject to the policies and Conditions of Sale in force at the time you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.</p>
                                    <ol start="10">
                                        <li><strong> Events beyond our reasonable control</strong></li>
                                    </ol>
                                    <p>We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.</p>
                                    <ol start="11">
                                        <li><strong> Waiver</strong></li>
                                    </ol>
                                    <p>If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.</p>
                                    <ol start="12">
                                        <li><strong> Governing Law and Jurisdiction</strong></li>
                                    </ol>
                                    <p>These conditions are governed by and construed in accordance with the laws of India, and the application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Delhi.</p>
                                    <ol start="13">
                                        <li><strong> Terms and Conditions in Compliance with Consumer Protection (E-Commerce Rules, 2020):</strong></li>
                                    </ol>
                                    <p>The Company has amended it’s Terms and Conditions to include covenants in relation to:</p>
                                    <ol>
                                        <li>Complaint being raised by a user – Ticket number will be provided to the user against the complaint raised so that the status of the complaint can be tracked.</li>
                                        <li>Security of the payment methods offered to the User- All payment modes will be secure and safe.</li>
                                    </ol>
                                    <p>Details of all relevant payment service providers – All payment service  providers will be displayed when making a payment.</p>
                                    <ol></ol>
                                </div>
                            </article>
                        </div>

                    </div>
                </div>

            </div>
            <footer className="page-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer-widget-area">
                                <div className="widget widget_text">
                                    <div className="textwidget">
                                        <div className="kiko-logo">
                                            <img src={KikoLogo} />
                                        </div>
                                        <p style={{ color: "#ffffffbf" }}>
                                            614, Avior Corporate Park, LBS Road, Mulund (W),
                                            Mumbai 400080,
                                            <br />
                                            Maharashtra, India.
                                        </p>
                                        <p>
                                            <a href="mailto:support@kiko.media">
                                                support@kiko.media
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="widget widget_text">
                                    <div className="textwidget">
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://facebook.com/profile.php/?id=100090104332961"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="socialIcon"
                                                >
                                                    <img src={Facebook} alt="Facebook" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.instagram.com/kikoliveapp/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="socialIcon"
                                                >
                                                    <img src={Insta} alt="Instagram" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://in.linkedin.com/company/kikolive"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="socialIcon"
                                                >
                                                    <img src={LinkedIn} alt="LinkedIn" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.youtube.com/@kikolive5631"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="socialIcon"
                                                >
                                                    <img src={Youtube} alt="LinkedIn" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="footer-widget-area">
                                <div className="menu-footer-container">
                                    <ul className="menu">
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/disclaimer"
                                                className="menu-link"
                                            >
                                                Disclaimer
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/privacyPolicy"
                                                className="menu-link"
                                            >
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/deliveryPolicy"
                                                className="menu-link"
                                            >
                                                Delivery Policy
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/returnRefundPolicy"
                                                className="menu-link"
                                            >
                                                Return & Refund Policy
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/termsCondition"
                                                className="menu-link"
                                            >
                                                Terms & Conditions
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="downloadAppButtons">
                                    <div className="AppButtons">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://apps.apple.com/in/app/kiko-live/id1567183166"
                                            className="downloadLink"
                                        >
                                            <img src={AppStore} alt="App Store" />
                                        </a>
                                    </div>
                                    <div className="AppButtons">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://play.google.com/store/apps/details?id=live.kiko.user&pcampaignid=web_share"
                                            className="downloadLink"
                                        >
                                            <img src={PlayStore} alt="Play Store" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};
export default TermsCondition;