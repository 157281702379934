import React, { useEffect, useState } from "react";
import Header from "../Header/component";
import Plus from "../../components/svgicons/plus";
import ThreeDots from "../../images/DotsThree.svg";
import API from "../../api";
import LeftArrow from "../../components/svgicons/leftArrow";
import { get } from "lodash";
import { Modal } from "reactstrap";
import { ADD_USER_ADDRESS, GET_USER_ADDRESS } from "../../api/apiList";
import { handleError, sellerDetails } from "../../components/common/utils";
import { useSelector } from "react-redux";
import blacksearchIcon from "../../images/blacksearchicon.svg";
import locationIcon from "../../images/location-icon.svg";
import searchIcon from "../../images/searchIcon.svg";
import WrappedMap from "../../components/Map/map";
import Loader from "../../components/common/loader";
import {
  getPlacesDetails,
  placeDetail,
  handleAddress,
} from "../../api/ApiService";
import { useNavigate } from "react-router-dom";
import store from "../../store";
import {
  getLocations,
  startLoadingMap,
  selectAddressMapLoading,
} from "../../slices/mapSlice";
import debounce from "lodash/debounce";
import { USER_DATA } from "../../components/common/constant";

const ManageAddress = () => {
  const navigate = useNavigate();
  const [userAddress, setUserAddress] = useState([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  ///// for Modal
  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem(USER_DATA) || "");
    } catch (error) {
      return null;
    }
  };
  const userData = getUserFromLocalStorage();
  const address = useSelector((s) => s.mapApi.address);
  const [tempAddress, setTempAddress] = useState({});

  const [getCurrentLocationLoader, setGetCurrentLocationLoader] =
    useState(false);

  const [loadingAddAddress, setLoadingAddAddress] = useState(false);
  //   const [buildingNumber, setBuildingNumber] = useState("");
  const [addDetailsModal, setAddDetailsModal] = useState(false);
  const [searchmodal, setSearchmodal] = useState(false);
  const [openFromEdit, setOpenFromEdit] = useState(false);

  let cartItems = JSON.parse(
    localStorage.getItem("cart_" + sellerDetails()?._id)
  );

  const locations = useSelector((s) => s.mapApi.locations);
  const loadingMapAddress = useSelector((s) => s.mapApi.startLoadingMap);
  const selectAddressLoader = useSelector(
    (s) => s.mapApi.selectAddressMapLoading
  );

  //   const pinCodeDetails = useSelector((s) => s.mapApi.pinCodeDetails);
  const [map, setMap] = useState(false);
  console.log("User Data : ", userData);
  const [landmark, setLandmark] = useState("");
  const [emailId, setEmailId] = useState(userData ? userData.email : "");
  const [buyerName, setBuyerName] = useState(userData ? userData.name : "");
  const [buyerNumber, setBuyerNumber] = useState(
    userData ? userData.mobile : ""
  );

  useEffect(() => {
    setTempAddress(address);
    if (address?.latitude) {
      setMap(true);
    }
  }, [address]);

  const checkValidation = (value) => {
    // console.log("VALUE : ", value);
    if (!value || value === undefined || value == null || value === "") {
      return false;
    } else {
      return true;
    }
  };

  const onChangePincodeDetail = (e) => {
    // just won't work, this callback is debounced
    // console.log("debounce=====>>>");
    // console.log("API SERVICE : HANDLE ADDRESS ", address);
    if (e.target.value !== "" && e.target.value.length >= 3) {
      handleAddress(e.target.value);
    } else {
      store.dispatch(startLoadingMap(false));
      store.dispatch(getLocations([]));
    }
  };
  const debouncedOnChange = debounce(onChangePincodeDetail, 500);

  const SearchResult = () => {
    const searchLocations = locations.map((item) => (
      <div
        className="resultList"
        onClick={() => {
          setSearchmodal(false);
          setTempAddress({});
          placeDetail(item?.place_id);
        }}
      >
        <img src={blacksearchIcon} alt="Search Icon" />
        <div className="resultText">
          <h4>{item?.structured_formatting?.main_text}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    ));

    return <>{searchLocations}</>;
  };

  const deleteAddress = (address) => {
    API.delete(ADD_USER_ADDRESS + "/" + address?._id)
      .then(({ data }) => {
        if (data) {
          const newData = data?.data.filter(function (item) {
            return item?.status !== "Delete";
          });

          setUserAddress(newData);
          setAddDetailsModal(false);
          setTempAddress({});
        }
      })
      .catch((error) => {
        handleError(error);
        setTempAddress({});
        setAddDetailsModal(false);
      });
  };

  const saveAddress = () => {
    console.log("Saving address", tempAddress);
    const isBuyerEmailValid = checkValidation(emailId);
    const isPhoneNumberValid = checkValidation(buyerNumber);
    const isPinCodeValid = checkValidation(
      tempAddress?.pincode ? tempAddress.pincode : tempAddress.zipcode
    );
    const isLandmarkValid = checkValidation(landmark);
    const isStateValid = checkValidation(tempAddress.state);
    const isaddressLine2Valid = checkValidation(tempAddress.address_line2);
    const isCityValid = checkValidation(tempAddress.city);
    const isLatitudeValid = checkValidation(
      tempAddress.latitude
        ? tempAddress?.latitude
        : tempAddress?.region?.latitude
    );
    const isLongitudeValid = checkValidation(
      tempAddress.longitude
        ? tempAddress?.longitude
        : tempAddress?.region?.longitude
    );

    console.log(
      "Please enter valid details" +
        isBuyerEmailValid +
        isPhoneNumberValid +
        isPinCodeValid +
        isLandmarkValid +
        isStateValid +
        isaddressLine2Valid +
        isCityValid +
        isLatitudeValid +
        isLongitudeValid
    );
    if (
      !isBuyerEmailValid ||
      !isPhoneNumberValid ||
      !isPinCodeValid ||
      !isLandmarkValid ||
      !isStateValid ||
      !isaddressLine2Valid ||
      !isCityValid ||
      !isLatitudeValid ||
      !isLongitudeValid
    ) {
      alert("Please enter valid details");
    } else {
      setLoadingAddAddress(true);
      const region = {
        latitude: parseFloat(
          tempAddress.latitude
            ? tempAddress?.latitude
            : tempAddress?.region?.latitude
        ),
        longitude: parseFloat(
          tempAddress.longitude
            ? tempAddress?.longitude
            : tempAddress?.region?.longitude
        ),
      };
      const body = {
        contactName: buyerName,
        contactPhone: `+91${buyerNumber}`,
        city: tempAddress.city,
        state: tempAddress.state,
        zipcode: tempAddress?.pincode
          ? tempAddress.pincode
          : tempAddress.zipcode,
        address_line1: tempAddress?.city ? tempAddress.city : "",
        address_line2: tempAddress.address_line2,
        landmark: landmark,
        tag: tempAddress?.tag,
        region: region,
        status: "Default",
      };

      console.log("USER ADDRESS: Add ADDRESS ", body);

      //    API.put(ADD_USER_ADDRESS);
      let apiReq = null;

      if (tempAddress?._id) {
        body.addressId = tempAddress?._id;
        apiReq = API.put(ADD_USER_ADDRESS, body);
      } else {
        apiReq = API.post(ADD_USER_ADDRESS, body);
      }
      //   API.post(ADD_USER_ADDRESS + `?userId=${userData?._id}`, body)
      apiReq
        .then(({ data }) => {
          // console.log("Saving address 11");
          // console.log("USER ADDRESS: Add ADDRESS ", data);
          if (data) {
            // setEstimationData(get(data, 'data', {}))
            // setKikoDelivery(get(data, 'data.kikoDelivery', false))
            // console.log("USER ADDRESS: Add ADDRESS ", data);
            const newData = data?.data.filter(function (item) {
              return item?.status !== "Delete";
            });

            setUserAddress(newData);
            // if (data?.data.length > 0) {
            //   getDeliveryEstimation(data?.data[0]);
            // }
            setAddDetailsModal(false);
            setTempAddress({});
            setLoadingAddAddress(false);
            setLandmark("");
          }
        })
        .catch((error) => {
          handleError(error);
          setAddDetailsModal(false);
          setLoadingAddAddress(false);
          setLandmark("");
        });
    }
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      setGetCurrentLocationLoader(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setTempAddress({
            ...tempAddress,
            latitude: latitude,
            longitude: longitude,
          });
          // setTempAddress({ ...tempAddress, longitude: longitude });
          const obj = { latitude, longitude };
          getPlacesDetails(obj);
          setMap(true);
          setSearchmodal(false);
          setGetCurrentLocationLoader(false);
          // console.log("latitude, longitude ", obj);
        },
        (error) => {
          setGetCurrentLocationLoader(false);
          // console.error('Error getting current location:', error);
          // notify("error", error.message)
          alert("Error in facting your current location Try Again!");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    API.get(GET_USER_ADDRESS, { userId: get(user, "_id", "") })
      .then(({ data }) => {
        if (data) {
          const newData = data?.data.filter(function (item) {
            return item?.status !== "Delete";
          });
          setUserAddress(newData);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  // const ThreeDot = (
  //     <div className="editdots">
  //         <p className="m-0" onClick={() => {
  //             setAddDetailsModal(true)
  //             setOpenFromEdit(true)
  //             console.log("MANAGE ADDRESS : ", tempAddress);
  //         }}>Edit</p>
  //         <p className="m-0">Delete</p>
  //     </div>
  // );
  return (
    <>
      <Header cartItemLength={cartItems?.length} />
      <div className="productSection">
        <div className="container">
          <div className="DetailsSection">
            <div className="d-flex align-items-center justify-content-between gap-2 py-3">
              <div className="d-flex align-items-center">
                <LeftArrow
                  className="me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <h5 className="m-0">Manage Address</h5>
              </div>
              <button
                className=" btn Dark-btn"
                onClick={() => {
                  setSearchmodal(true);
                }}
              >
                <Plus className="me-2" type="button" />
                Add Address
              </button>
            </div>
            <div class="cardgrid pb-3" style={{ maxHeight: "100%" }}>
              {userAddress && userAddress.length > 0
                ? userAddress.map((data, index) => {
                    return (
                      <div class="card" key={data?._id}>
                        <label>
                          {/* <input name="plan" defaultChecked={index === 0 ? true : false} class="radio" type="radio" /> */}
                          <div class="plan-details" aria-hidden="true">
                            <div class="plan-type p-0">
                              <span>
                                {data.contactName}{" "}
                                <span>({data.tag ? data.tag : "Other"})</span>
                              </span>
                            </div>
                            <p class="plan-cost">{data.contactPhone}</p>
                            <p className="plan-cost">{data.address_line2}</p>
                            <p className="m-0">
                              {data.city} {data.zipcode}
                            </p>
                          </div>
                        </label>
                        {/* <Popover content={ThreeDot} trigger="click" onClick={() => { setTempAddress(data) }}>
                                                <img src={ThreeDots} className="three-dots" alt="Three Dots" />
                                            </Popover> */}
                        <div className="threedotsDropdown">
                          <div class="dropdown">
                            <p
                              class=" threedots"
                              role="button"
                              id="dropdownMenuLink"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img
                                src={ThreeDots}
                                className="three-dots"
                                alt="Three Dots"
                              />
                            </p>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <p
                                class="dropdown-item m-0"
                                onClick={() => {
                                  setAddDetailsModal(true);
                                  setOpenFromEdit(true);
                                  setTempAddress(data);
                                  console.log("MANAGE ADDRESS : ", data);
                                }}
                              >
                                Edit
                              </p>
                              <p
                                class="dropdown-item m-0"
                                onClick={() => {
                                  setOpenDeletePopup(true);
                                  setTempAddress(data);
                                  console.log("MANAGE ADDRESS : ", data);
                                }}
                              >
                                Delete
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={searchmodal}
        toggle={() => {
          setSearchmodal(false);
        }}
        aria-labelledby="contained-modal-title-v"
        ed
        style={{ maxWidth: "600px" }}
        centered
      >
        <div className="addressmodalBlock">
          <h2>Search Location</h2>
          <div className="searchBlock">
            <img src={searchIcon} alt="Search1 Icon" />
            <input
              type="search"
              placeholder="Search for an area, location name"
              onChange={debouncedOnChange}
            />
          </div>
          {/* <div
                        className="resultList"
                        onClick={() => {
                            setSearchmodal(false);
                        }}
                    >
                        <img src={blacksearchIcon} alt="Search Icon" />
                        <div className="resultText">
                            <h4>{pinCodeDetails.city}</h4>
                            <p>{pinCodeDetails.pincode}</p>
                        </div>
                    </div> */}
          {loadingMapAddress && <Loader />}
          <div className="currenLocation">
            <img src={locationIcon} alt="Location Icon" />
            {getCurrentLocationLoader ? (
              <Loader />
            ) : (
              <button
                className="AddAddress"
                onClick={() => {
                  handleGetCurrentLocation();
                }}
              >
                Use Current Location{" "}
              </button>
            )}
          </div>
          <div className={locations.length > 0 && "redultListBlock"}>
            {locations.length > 0 && SearchResult()}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={map}
        aria-labelledby="contained-modal-title-v"
        ed
        toggle={() => {
          setMap(false);
        }}
        style={{ maxWidth: "600px" }}
        centered
      >
        <div className="DropLocationModal">
          <h2>Set Delivery Location</h2>
          <div className="DroMapBlock">
            {
              <WrappedMap
                currLatlong1={{
                  latitude: tempAddress.latitude,
                  longitude: tempAddress.longitude,
                }}
                func={(obj) => {
                  getPlacesDetails(obj);
                }}
              />
            }
          </div>
          <div className="apartmentBlock mb-3">
            <div class="apartmentText">
              <div>
                <p>{address.title}</p>
              </div>
            </div>
          </div>
          <div className="button-loader">
            <button
              disabled={loadingMapAddress}
              onClick={() => {
                setAddDetailsModal(true);
                setOpenFromEdit(false);
              }}
            >
              Proceed to add details
            </button>
            <div className="dotLoader">{loadingMapAddress && <Loader />}</div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={addDetailsModal}
        toggle={() => {
          setAddDetailsModal(false);
        }}
        aria-labelledby="contained-modal-title-v"
        ed
        size="lg"
        style={{ maxWidth: "500px" }}
        centered
      >
        <div className="addressmodalBlock">
          <div className="d-flex mb-3">
            {!openFromEdit && (
              <button
                className="changeLocation ms-auto"
                onClick={() => {
                  setAddDetailsModal(false);
                  setMap(true);
                }}
              >
                Change
              </button>
            )}
          </div>
          <form>
            <div className="row">
              <div className="col-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full Name*"
                  value={buyerName}
                  onChange={(e) => {
                    setBuyerName(e.target.value);
                  }}
                />
                {checkValidation(buyerName) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Alternate Number*"
                  value={buyerNumber}
                  onChange={(e) => {
                    setBuyerNumber(e.target.value);
                  }}
                />
                {checkValidation(buyerNumber) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email Id*"
                  value={emailId}
                  onChange={(e) => {
                    setEmailId(e.target.value);
                  }}
                />
                {checkValidation(emailId) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pincode*"
                  value={
                    tempAddress?.pincode
                      ? tempAddress.pincode
                      : tempAddress.zipcode || ""
                  }
                  onChange={(e) => {
                    setTempAddress({ ...tempAddress, pincode: e.target.value });
                  }}
                />
                {checkValidation(
                  tempAddress?.pincode
                    ? tempAddress.pincode
                    : tempAddress.zipcode
                ) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City*"
                  value={tempAddress?.city || ""}
                  onChange={(e) => {
                    setTempAddress({ ...tempAddress, city: e.target.value });
                  }}
                />
                {checkValidation(tempAddress?.city) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State*"
                  value={tempAddress?.state || ""}
                  onChange={(e) => {
                    setTempAddress({ ...tempAddress, state: e.target.value });
                  }}
                />
                {checkValidation(tempAddress?.state) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              {/* <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Block, Building Number* "
                  value={buildingNumber}
                  onChange={(e) => {
                    setBuildingNumber(e.target.value);
                  }}
                />
                {checkValidation(buildingNumber) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div> */}
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Area, Colony, Street*"
                  value={tempAddress.address_line2}
                  onChange={(e) => {
                    setTempAddress({
                      ...tempAddress,
                      address_line2: e.target.value,
                    });
                  }}
                />
                {checkValidation(tempAddress?.address_line2) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Landmark*"
                  value={landmark}
                  onChange={(e) => {
                    setLandmark(e.target.value);
                  }}
                />
                {checkValidation(landmark) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <label>Address Type</label>
                <div className="address-type">
                  <label>
                    Home
                    <input
                      type="radio"
                      name="gender"
                      defaultChecked={
                        tempAddress?.tag?.toLowerCase() === "home"
                      }
                      onChange={(e) => {
                        setTempAddress({ ...tempAddress, tag: "HOME" });
                      }}
                    />
                  </label>
                  <label>
                    Work
                    <input
                      type="radio"
                      name="gender"
                      defaultChecked={
                        tempAddress?.tag?.toLowerCase() === "work"
                      }
                      onChange={(e) => {
                        setTempAddress({ ...tempAddress, tag: "WORK" });
                      }}
                    />
                  </label>
                  <label>
                    Other
                    <input
                      type="radio"
                      name="gender"
                      defaultChecked={
                        tempAddress?.tag?.toLowerCase() === "other"
                      }
                      onChange={(e) => {
                        setTempAddress({ ...tempAddress, tag: "OTHER" });
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </form>
          <div className="button-loader mt-3">
            <button
              disabled={loadingAddAddress}
              className="btn Dark-btn w-100"
              type="button"
              onClick={() => {
                saveAddress();
                setMap(false);
              }}
            >
              SAVE & PROCEED
            </button>

            <div className="dotLoader">{loadingAddAddress && <Loader />}</div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={selectAddressLoader}
        toggle={() => {
          store.dispatch(selectAddressMapLoading(false));
        }}
        aria-labelledby="contained-modal-title-v"
        ed
        size="lg"
        style={{ maxWidth: "500px" }}
        centered
      >
        <div className="dotLoader">{selectAddressLoader && <Loader />}</div>
      </Modal>
      <Modal
        isOpen={openDeletePopup}
        toggle={() => {
          setOpenDeletePopup(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ maxWidth: "600px" }}
      >
        <div className="addressmodalBlock">
          <h2>Delete Address</h2>
          <p>Are you sure you want to delete this address?</p>
          <div className="d-flex justify-content-end">
            <button
              className="btn Dark-btn"
              onClick={() => {
                setOpenDeletePopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn Dark-btn mx-2"
              onClick={() => {
                setOpenDeletePopup(false);
                deleteAddress(tempAddress);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ManageAddress;
