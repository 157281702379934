import React, {useEffect, useState} from "react";
import Header from "../Header/component";
// import ThreeDots from "../../images/DotsThree.svg";
import Plus from "../../components/svgicons/plus";
import API from "../../api";
import {
  ADD_USER_ADDRESS,
  ESTIMATION,
  GET_USER_ADDRESS,
} from "../../api/apiList";
import {get} from "lodash";
import {handleError, sellerDetails} from "../../components/common/utils";
import {useNavigate} from "react-router-dom";
import {Modal} from "reactstrap";
import {
  getPlacesDetails,
  placeDetail,
  handleAddress,
} from "../../api/ApiService";
import {useSelector} from "react-redux";
import blacksearchIcon from "../../images/blacksearchicon.svg";
import locationIcon from "../../images/location-icon.svg";
import LeftArrow from "../../components/svgicons/leftArrow";
import WrappedMap from "../../components/Map/map";
import Loader from "../../components/common/loader";
import debounce from "lodash/debounce";
import {
  getLocations,
  startLoadingMap,
  selectAddressMapLoading,
} from "../../slices/mapSlice";
import store from "../../store";
import ThreeDots from "../../images/DotsThree.svg";

const UserAddress = () => {
  const [getCurrentLocationLoader, setGetCurrentLocationLoader] =
    useState(false);
  const [userAddress, setUserAddress] = useState([]);
  const [estimationData, setEstimationData] = useState({});
  const [kikoDelivery, setKikoDelivery] = useState(false);
  const [selectUserAddress, setSelectUserAddress] = useState({});
  const [addDetailsModal, setAddDetailsModal] = useState(false);
  const [searchmodal, setSearchmodal] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const navigate = useNavigate();

  const [loadingAddress, setLoadingAddress] = useState(true);
  const [loadingSelectAddress, setLoadingSelectAddress] = useState(false);
  const [loadingAddAddress, setLoadingAddAddress] = useState(false);
  const [selectDeleteAddress, setDeletedAddress] = useState({});

  const address = useSelector((s) => s.mapApi.address);
  const loadingMapAddress = useSelector((s) => s.mapApi.startLoadingMap);
  const selectAddressLoader = useSelector(
    (s) => s.mapApi.selectAddressMapLoading
  );

  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem("user") || "");
    } catch (error) {
      return null;
    }
  };
  const userData = getUserFromLocalStorage();

  const [landmark, setLandmark] = useState("");
  const [emailId, setEmailId] = useState(userData ? userData.email : "");
  const [buyerName, setBuyerName] = useState(userData ? userData.name : "");
  const [buyerNumber, setBuyerNumber] = useState(
    userData ? userData.mobile : ""
  );

  const [tempAddress, setTempAddress] = useState({});
  const [openFromEdit, setOpenFromEdit] = useState(false);

  useEffect(() => {
    setTempAddress(address);
    if (address?.latitude) {
      setMap(true);
    }
  }, [address]);

  let cartItems = JSON.parse(
    localStorage.getItem("cart_" + sellerDetails()?._id)
  );

  const [cartItemLength, setCartItemLength] = useState(cartItems?.length);

  const locations = useSelector((s) => s.mapApi.locations);

  const [map, setMap] = useState(false);

  let totalDiscountedPrice = 0;
  let totalWeight = 0;
  if (cartItems && cartItems !== undefined) {
    cartItems.forEach((item) => {
      totalDiscountedPrice += item.quantity * item.catalogData.discountedPrice;
      if (
        item?.catalogData.weightUnit == "GRAMS" ||
        item?.catalogData.weightUnit == "ML"
      ) {
        totalWeight += (item?.catalogData.weight / 1000) * item.quantity;
      } else {
        totalWeight += item?.catalogData.weight * item?.quantity;
      }
    });
  }
  const getUseAddress = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    API.get(GET_USER_ADDRESS, {userId: get(user, "_id", "")})
      .then(({data}) => {
        if (data) {
          const newData = data?.data?.filter(function (item) {
            return item?.status !== "Delete";
          });
          setUserAddress(newData);
          setLoadingAddress(false);
          if (newData?.length > 0) {
            getDeliveryEstimation(newData[0]);
          }
        }
        // console.log("USERADDRESS:", "VIJENDRA")
      })
      .catch((error) => {
        setLoadingAddress(false);
        const err = get(error, "response.data.error", "");
        if (
          get(err, "code", "") === 401 &&
          get(err, "stack", "") === "Unauthorized access"
        ) {
          navigate("/cart");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          return;
        }
        handleError(error);
      });
  };
  useEffect(() => {
    getUseAddress();
  }, []);

  //   "websiteOrderServiceability": {
  //     "freeDelivery": true,
  //     "panIndiaDelivery": true,
  //     "panIndiaDeliveryCharges": 99
  // },

  const getDeliveryEstimation = (selectUserAddress) => {
    if (selectUserAddress) {
      setSelectUserAddress(selectUserAddress);
      if (
        get(
          sellerDetails(),
          "websiteOrderServiceability.freeDelivery",
          false
        ) &&
        get(
          sellerDetails(),
          "websiteOrderServiceability.panIndiaDelivery",
          false
        )
      ) {
        const data = {
          discountOffered: 0,
          kikoDelivery: false,
          reason: "Delivery service available.",
          distance: 0,
          price: 0,
          deliveryAllocatePriority1: "borzo",
          deliveryAllocatePriority2: "dunzo",
          deliveryAllocation: "",
        };
        setEstimationData(data);
        setKikoDelivery(true);
      } else {
        setLoadingSelectAddress(true);
        const obj = {
          userId: get(selectUserAddress, "user_id", {}),
          vendorId: get(sellerDetails(), "_id", ""),
          userLatitude: get(selectUserAddress, "region.latitude", {}),
          userLongitude: get(selectUserAddress, "region.longitude", {}),
          usersAddress: get(selectUserAddress, "address_line2", {}),
          requestByVendor: false,
          totalWeight: totalWeight > 6 ? 6 : totalWeight,
          freeDelivery: false,
          userPincode: get(selectUserAddress, "zipcode", {}),
          orderCreatedBy: "buyer",
        };
        API.post(ESTIMATION, obj)
          .then(({data}) => {
            if (data.success) {
              if (
                get(
                  sellerDetails(),
                  "websiteOrderServiceability.freeDelivery",
                  false
                ) &&
                !get(
                  sellerDetails(),
                  "websiteOrderServiceability.panIndiaDelivery",
                  false
                )
              ) {
                const data1 = {
                  discountOffered: 0,
                  kikoDelivery: false,
                  reason: get(data, "data.reason", "Area Not Servicable"),
                  distance: 0,
                  price: 0,
                  deliveryAllocatePriority1: "borzo",
                  deliveryAllocatePriority2: "dunzo",
                  deliveryAllocation: "",
                };
                setEstimationData(data1);
                setKikoDelivery(get(data, "data.kikoDelivery", false));
              } else if (
                get(
                  sellerDetails(),
                  "websiteOrderServiceability.panIndiaDelivery",
                  false
                ) &&
                !get(data, "data.kikoDelivery", false)
              ) {
                const data = {
                  discountOffered: 0,
                  kikoDelivery: false,
                  reason: "Delivery service available.",
                  distance: 0,
                  price: get(
                    sellerDetails(),
                    "websiteOrderServiceability.panIndiaDeliveryCharges",
                    0
                  ),
                  deliveryAllocatePriority1: "borzo",
                  deliveryAllocatePriority2: "dunzo",
                  deliveryAllocation: "",
                };
                setEstimationData(data);
                setKikoDelivery(true);
              } else {
                setEstimationData(get(data, "data", {}));
                setKikoDelivery(get(data, "data.kikoDelivery", false));
                get(estimationData, "reason", "Area Not Servicable");
                // console.log("USERADDRESS: DELIVERY CHARGES", get(data, 'data', {}))
                // reason
                totalDiscountedPrice += get(data, "data.price", 0);
              }
            }
            setLoadingSelectAddress(false);
          })
          .catch((error) => {
            handleError(error);
            setLoadingSelectAddress(false);
          });
      }
    }
  };

  const saveAddress = () => {
    const isBuyerEmailValid = checkValidation(emailId);
    const isPhoneNumberValid = checkValidation(buyerNumber);
    const isPinCodeValid = checkValidation(
      tempAddress?.pincode ? tempAddress.pincode : tempAddress.zipcode
    );
    const isLandmarkValid = checkValidation(landmark);
    const isStateValid = checkValidation(tempAddress.state);
    const isAddressLine2Valid = checkValidation(tempAddress.address_line2);
    const isCityValid = checkValidation(tempAddress.city);
    const isLatitudeValid = checkValidation(
      tempAddress.latitude
        ? tempAddress?.latitude
        : tempAddress?.region?.latitude
    );
    const isLongitudeValid = checkValidation(
      tempAddress.longitude
        ? tempAddress?.longitude
        : tempAddress?.region?.longitude
    );
    if (
      !isBuyerEmailValid ||
      !isPhoneNumberValid ||
      !isPinCodeValid ||
      !isLandmarkValid ||
      !isStateValid ||
      !isAddressLine2Valid ||
      !isCityValid ||
      !isLatitudeValid ||
      !isLongitudeValid
    ) {
      alert("Enter valid address");
    } else {
      setLoadingAddAddress(true);
      const region = {
        latitude: tempAddress.latitude
          ? tempAddress?.latitude
          : tempAddress?.region?.latitude,
        longitude: tempAddress.longitude
          ? tempAddress?.longitude
          : tempAddress?.region?.longitude,
      };
      // const token = JSON.parse(localStorage.getItem("token") || "")
      const body = {
        contactName: buyerName,
        contactPhone: `+91${buyerNumber}`,
        city: tempAddress.city,
        state: tempAddress.state,
        zipcode: tempAddress?.pincode
          ? tempAddress.pincode
          : tempAddress.zipcode,
        address_line1: tempAddress?.city ? tempAddress.city : "",
        address_line2: tempAddress.address_line2,
        landmark: landmark,
        tag: tempAddress?.tag,
        region: region,
      };
      let apiReq = null;

      if (tempAddress?._id) {
        body.addressId = tempAddress?._id;
        apiReq = API.put(ADD_USER_ADDRESS, body);
      } else {
        apiReq = API.post(ADD_USER_ADDRESS, body);
      }
      apiReq
        .then(({data}) => {
          if (data) {
            const newData = data?.data.filter(function (item) {
              return item?.status !== "Delete";
            });
            setUserAddress(newData);
            if (newData?.length > 0) {
              getDeliveryEstimation(newData[0]);
            }
          }
          setAddDetailsModal(false);
          setLoadingAddAddress(false);
          setTempAddress({});
          setLandmark("");
        })
        .catch((error) => {
          handleError(error);
          setAddDetailsModal(false);
          setLoadingAddAddress(false);
          setTempAddress({});
          setLandmark("");
        });
    }
  };

  const checkValidation = (value) => {
    if (!value || value === undefined || value == null || value === "") {
      return false;
    } else {
      return true;
    }
  };
  const onChangePincodeDetail = (e) => {
    // just won't work, this callback is debounced
    // console.log("SEARCH TEXT : ", e.target.value)
    if (e.target.value !== "" && e.target.value.length >= 3) {
      handleAddress(e.target.value);
    } else {
      store.dispatch(startLoadingMap(false));
      store.dispatch(getLocations([]));
    }
  };

  const debouncedOnChange = debounce(onChangePincodeDetail, 500);

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      setGetCurrentLocationLoader(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const {latitude, longitude} = position.coords;
          setTempAddress({
            ...tempAddress,
            latitude: latitude,
            longitude: longitude,
          });
          const obj = {latitude, longitude};
          getPlacesDetails(obj);
          setMap(true);
          setSearchmodal(false);
          // console.log("latitude, longitude ", obj)
          setGetCurrentLocationLoader(false);
        },
        (error) => {
          setGetCurrentLocationLoader(false);
          alert("Error in facting your current location Try Again!");
          // console.error('Error getting current location:', error);
          // notify("error", error.message)
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  const deleteAddress = (address) => {
    API.delete(ADD_USER_ADDRESS + "/" + address?._id)
      .then(({data}) => {
        if (data) {
          const newData = data?.data.filter(function (item) {
            return item?.status !== "Delete";
          });
          setUserAddress(newData);
          if (newData?.length > 0) {
            getDeliveryEstimation(newData[0]);
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const SearchResult = () => {
    const searchLocations = locations.map((item) => (
      <div
        className="resultList"
        onClick={() => {
          setSearchmodal(false);
          setTempAddress({});
          placeDetail(item?.place_id);
        }}
      >
        <img src={blacksearchIcon} alt="Search Icon" />
        <div className="resultText">
          <h4>{item?.structured_formatting?.main_text}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    ));

    return <>{searchLocations}</>;
  };
  return (
    <>
      <Header cartItemLength={cartItemLength} />
      <div className="productSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between gap-2 py-3">
                <div className="d-flex align-items-center gap-2">
                  <LeftArrow
                    className="me-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{cursor: "pointer"}}
                  />
                  <h4 className="cartView-address-type">Delivery Address</h4>
                </div>
                <button
                  className="btn Dark-btn"
                  onClick={() => {
                    setSearchmodal(true);
                  }}
                >
                  <Plus className="me-1" type="button" />
                  Add Address
                </button>
              </div>
            </div>
            <div className="col-lg-8 mb-3">
              <div
                className="cartView-body"
                style={{maxHeight: "calc(100vh - 170px)"}}
              >
                <div className="cartView-address-wrapper">
                  {loadingAddress ? (
                    <div className="dotLoader">
                      <Loader />
                    </div>
                  ) : (
                    <div class="cardgrid">
                      {userAddress && userAddress.length > 0 ? (
                        userAddress.map((data, index) => {
                          return (
                            <div class="card" key={data._id}>
                              <label>
                                <input
                                  name="plan"
                                  defaultChecked={index === 0 ? true : false}
                                  class="radio"
                                  type="radio"
                                  onClick={() => {
                                    getDeliveryEstimation(data);
                                  }}
                                />
                                <div
                                  class="plan-details"
                                  aria-hidden="true"
                                  style={{cursor: "pointer"}}
                                >
                                  <div class="plan-type">
                                    <span>
                                      {data.tag ? data.tag : "Other"} (
                                      {data.contactName})
                                    </span>
                                  </div>
                                  <p class="plan-cost">{data.contactPhone}</p>
                                  <p class="plan-cost">{data.address_line2}</p>
                                  <p className="m-0">
                                    {data.city} {data.zipcode}
                                  </p>
                                </div>
                              </label>
                              <div className="threedotsDropdown">
                                <div class="dropdown">
                                  <p
                                    class=" threedots"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img
                                      src={ThreeDots}
                                      className="three-dots"
                                      alt="Three Dots"
                                    />
                                  </p>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <p
                                      class="dropdown-item m-0"
                                      onClick={() => {
                                        setAddDetailsModal(true);
                                        setOpenFromEdit(true);
                                        setTempAddress(data);
                                        console.log("MANAGE ADDRESS : ", data);
                                      }}
                                    >
                                      Edit
                                    </p>
                                    <p
                                      class="dropdown-item m-0"
                                      onClick={() => {
                                        // deleteAddress(data);
                                        setOpenDeletePopup(true);
                                        setDeletedAddress(data);
                                        console.log("MANAGE ADDRESS : ", data);
                                      }}
                                    >
                                      Delete
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          className="notfounderror"
                          style={{position: "absolute"}}
                        >
                          <h1 className="no-found-data">Address not found</h1>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="priceDetails mb-3">
                <div className="cartView-header">
                  <h4 className="m-0">Price Details</h4>
                </div>
                <div className="cartView-body">
                  <div className="cartView_provider">
                    {cartItems?.map((cartData) => {
                      return (
                        <div className="d-flex align-items-center border-bottom-dotted ">
                          <div className="pe-2 flex-grow-1">
                            <p className="cartView-product-name">
                              {cartData.catalogData.productName}
                            </p>
                            <p className="cartView_ordered">
                              Quantity: {cartData.quantity}{" "}
                            </p>
                          </div>
                          <div className="ms-auto d-flex align-items-center">
                            <p className="cartView-sub-total">
                              <span className="me-1">₹</span>
                              {cartData.quantity *
                                cartData.catalogData.discountedPrice}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="cartView-card-footer ">
                  <div className="py-1 d-flex align-items-center">
                    <p className="cartView-card-body">Sub Total</p>
                    <div className="ms-auto d-flex align-items-center">
                      <p className="cartView-sub-total">
                        <span className="me-1">₹</span>
                        {totalDiscountedPrice}
                      </p>
                    </div>
                  </div>
                  <div className="py-1 d-flex align-items-center">
                    <p className="cartView-card-body">Delivery Charges</p>
                    <div className="ms-auto d-flex align-items-center">
                      <p className="cartView-sub-total">
                        <span className="me-1">₹</span>
                        {get(estimationData, "price", 0.0)}
                      </p>
                    </div>
                  </div>
                  <div className="py-1 d-flex align-items-center">
                    <p className="cartView-card-body">
                      <b>Total</b>
                    </p>
                    <div className="ms-auto d-flex align-items-center">
                      <p className="cartView-sub-total">
                        <span className="me-1">₹</span>
                        {totalDiscountedPrice +
                          get(estimationData, "price", 0.0)}
                      </p>
                    </div>
                  </div>
                  {kikoDelivery ? null : (
                    <div className="py-1 d-flex align-items-center">
                      <p className="cartView-card-body">
                        <b className="error">
                          {get(estimationData, "reason", "Area Not Servicable")}
                        </b>
                      </p>
                    </div>
                  )}
                </div>

                <div className="ps-3 pe-3 py-2 button-loader">
                  <button
                    disabled={loadingSelectAddress || !kikoDelivery}
                    className="btn Dark-btn justify-content-center w-100 "
                    onClick={() => {
                      kikoDelivery
                        ? navigate("/checkout", {
                            state: {
                              userAddress: selectUserAddress,
                              estimationData: estimationData,
                              totalDiscountedPrice: totalDiscountedPrice,
                            },
                          })
                        : alert("Area not servicable");
                    }}
                  >
                    {loadingSelectAddress ? "" : "Proceed"}
                  </button>
                  <div className="dotLoader">
                    {loadingSelectAddress && <Loader />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={searchmodal}
        toggle={() => {
          setSearchmodal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{maxWidth: "600px"}}
      >
        <div className="addressmodalBlock">
          <h2>Search Location</h2>
          <div className="searchBlock">
            <img src={blacksearchIcon} alt="Search Icon" />
            <input
              type="search"
              placeholder="Search for an area, location name"
              onChange={debouncedOnChange}
            />
          </div>
          {loadingMapAddress && <Loader />}
          <div className="currenLocation">
            <img src={locationIcon} alt="Location Icon" />
            {getCurrentLocationLoader ? (
              <Loader />
            ) : (
              <button
                className="AddAddress"
                onClick={() => {
                  handleGetCurrentLocation();
                }}
              >
                Use Current Location{" "}
              </button>
            )}
          </div>
          <div className={locations.length > 0 && "redultListBlock"}>
            {locations.length > 0 && SearchResult()}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={map}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={() => {
          setMap(false);
        }}
        style={{maxWidth: "600px"}}
      >
        <div className="DropLocationModal">
          <h2>Set Delivery Location</h2>
          <div className="DroMapBlock">
            {
              <WrappedMap
                currLatlong1={{
                  latitude: tempAddress.latitude,
                  longitude: tempAddress.longitude,
                }}
                func={(obj) => {
                  getPlacesDetails(obj);
                }}
              />
            }
          </div>
          <div className="apartmentBlock mb-3">
            <div class="apartmentText">
              <div>
                <p>{address.title}</p>
              </div>
            </div>
          </div>
          <div className="button-loader">
            <button
              disabled={loadingMapAddress}
              onClick={() => {
                setMap(false);
                setAddDetailsModal(true);
                setOpenFromEdit(false);
              }}
            >
              Proceed to add details
            </button>
            <div className="dotLoader">{loadingMapAddress && <Loader />}</div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={addDetailsModal}
        toggle={() => {
          setAddDetailsModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        style={{maxWidth: "500px"}}
      >
        <div className="addressmodalBlock">
          <div className="d-flex mb-3">
            {!openFromEdit && (
              <button
                className="changeLocation ms-auto"
                onClick={() => {
                  setAddDetailsModal(false);
                  setMap(true);
                }}
              >
                Change
              </button>
            )}
          </div>
          <form>
            <div className="row">
              <div className="col-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full Name*"
                  value={buyerName}
                  onChange={(e) => {
                    setBuyerName(e.target.value);
                  }}
                />
                {checkValidation(buyerName) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Alternate Number*"
                  value={buyerNumber}
                  onChange={(e) => {
                    setBuyerNumber(e.target.value);
                  }}
                />
                {checkValidation(buyerNumber) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email Id*"
                  value={emailId}
                  onChange={(e) => {
                    setEmailId(e.target.value);
                  }}
                />
                {checkValidation(emailId) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pincode*"
                  value={
                    tempAddress?.pincode
                      ? tempAddress.pincode
                      : tempAddress.zipcode || ""
                  }
                  onChange={(e) => {
                    setTempAddress({...tempAddress, pincode: e.target.value});
                  }}
                />
                {checkValidation(
                  tempAddress?.pincode
                    ? tempAddress.pincode
                    : tempAddress.zipcode
                ) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City*"
                  value={tempAddress?.city || ""}
                  onChange={(e) => {
                    setTempAddress({...tempAddress, city: e.target.value});
                  }}
                />
                {checkValidation(tempAddress?.city) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State*"
                  value={tempAddress?.state || ""}
                  onChange={(e) => {
                    setTempAddress({...tempAddress, state: e.target.value});
                  }}
                />
                {checkValidation(tempAddress?.state) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Area, Colony, Street*"
                  value={tempAddress.address_line2}
                  onChange={(e) => {
                    setTempAddress({
                      ...tempAddress,
                      address_line2: e.target.value,
                    });
                  }}
                />
                {checkValidation(tempAddress?.address_line2) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Landmark*"
                  value={landmark}
                  onChange={(e) => {
                    setLandmark(e.target.value);
                  }}
                />
                {checkValidation(landmark) ? null : (
                  <p className="error">This feild is required.</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <label>Address Type</label>
                {/* <div className="address-type">
                  <label>
                    Home
                    <input type="radio" name="gender" />
                  </label>
                  <label>
                    Work
                    <input type="radio" name="gender" />
                  </label>
                  <label>
                    Other
                    <input type="radio" name="gender" />
                  </label>
                </div> */}
                <div className="address-type">
                  <label>
                    Home
                    <input
                      type="radio"
                      name="gender"
                      defaultChecked={
                        tempAddress?.tag?.toLowerCase() === "home"
                      }
                      onChange={(e) => {
                        setTempAddress({...tempAddress, tag: "HOME"});
                      }}
                    />
                  </label>
                  <label>
                    Work
                    <input
                      type="radio"
                      name="gender"
                      defaultChecked={
                        tempAddress?.tag?.toLowerCase() === "work"
                      }
                      onChange={(e) => {
                        setTempAddress({...tempAddress, tag: "WORK"});
                      }}
                    />
                  </label>
                  <label>
                    Other
                    <input
                      type="radio"
                      name="gender"
                      defaultChecked={
                        tempAddress?.tag?.toLowerCase() === "other"
                      }
                      onChange={(e) => {
                        setTempAddress({...tempAddress, tag: "OTHER"});
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </form>
          <div className="button-loader mt-3">
            <button
              disabled={loadingAddAddress}
              className="btn Dark-btn w-100"
              type="button"
              onClick={() => {
                saveAddress();
              }}
            >
              SAVE & PROCEED
            </button>

            <div className="dotLoader">{loadingAddAddress && <Loader />}</div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={selectAddressLoader}
        toggle={() => {
          store.dispatch(selectAddressMapLoading(false));
        }}
        aria-labelledby="contained-modal-title-v"
        ed
        size="lg"
        style={{maxWidth: "500px"}}
        centered
      >
        <div className="dotLoader">{selectAddressLoader && <Loader />}</div>
      </Modal>
      <Modal
        isOpen={openDeletePopup}
        toggle={() => {
          setOpenDeletePopup(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{maxWidth: "600px"}}
      >
        <div className="addressmodalBlock">
          <h2>Delete Address</h2>
          <p>Are you sure you want to delete this address?</p>
          <div className="d-flex justify-content-end">
            <button
              className="btn Dark-btn"
              onClick={() => {
                setOpenDeletePopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn Dark-btn mx-2"
              onClick={() => {
                setOpenDeletePopup(false);
                deleteAddress(selectDeleteAddress);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UserAddress;
