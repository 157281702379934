import React, { Fragment } from 'react'
import { toast } from 'react-toastify';
import { get } from 'lodash'
import 'react-toastify/dist/ReactToastify.css';


export const sellerDetails = () => {
  try {
    return JSON.parse(localStorage.getItem("sellerDetails"))
  } catch (error) {
    return null
  }
}


export const notify = (type, message) => {
  toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
  })
}
export const handleError = error => {
  if (error?.response?.data?.message === 'Unauthorized access') {
    notify('error', error.response.data.message)
  }

  if (error?.response && error?.response.data) {
    if (error?.response?.data?.error) {
      const err = get(error, 'response.data.error', '')
      if (
        get(err, 'code', '') === 401 &&
        get(err, 'stack', '') === 'Unauthorized access'
      ){
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // window.location.reload();
        return
      }
      toast(
        ({ closeToast }) =>
          typeof err === 'string'
            ? err.includes('not a valid phone number') ||
              err.includes('is not an SMS enabled phone number')
              ? 'That phone number is not recognized'
              : err
            : Object.keys(err).map((element, index) => (
              <Fragment key={index}>
                {err[element]}
                <br />
              </Fragment>
            )),
        {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      )
    } else {
      notify('error', error.response.data.message)
    }
  } else if (error.message) {
    notify(
      'error',
      error.message === 'Network Error'
        ? 'You are offline, please check your internet connection'
        : error.message
    )
  } else {
    notify('error', 'Something went wrong!')
  }
}

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() =>
      // alert(`${text} copied to clipboard!`)
      notify("success", `${text} copied to clipboard!`)
    )
    .catch((error) => notify("error", "Failed to copy text"));
};
