import React from "react";

const HamburgerNew = (props) => (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <circle cx="24" cy="24" r="24" fill="#fff"></circle>
    <path
       fill="currentColor"
      d="M33 24a.75.75 0 01-.75.75h-16.5a.75.75 0 110-1.5h16.5A.75.75 0 0133 24zm-17.25-5.25h16.5a.75.75 0 100-1.5h-16.5a.75.75 0 100 1.5zm16.5 10.5h-16.5a.75.75 0 100 1.5h16.5a.75.75 0 100-1.5z"
    ></path>
  </svg>
);

export default HamburgerNew;
