import React from "react";
import Header from "../Header/component";
import fashionImg from "../../images/fashionImg.png";
import LeftArrow from "../../components/svgicons/leftArrow";


const Profile = () => {


    return (
        <>
            <Header />
            <div className="productSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center gap-2 py-3">
                                <a href="/" style={{ color: "#000", textDecoration: "none" }}><LeftArrow /></a>
                                <h3 className="m-0">Profile</h3>
                            </div>
                            <div className="cartView-price mb-3">
                                <div className="step-completed">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <p className="type_label" style={{ textTransform: "uppercase" }}>Delivering to:</p>
                                            <p className="type_label"><b>dscfds</b></p>
                                            <p className="type_label">dcdc</p>
                                            <p className="type_label">dcvdfv</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="image-preview">
                                                <img src={fashionImg} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Profile