import React, { useState } from "react";
import Header from "../Header/component";
import LeftArrow from "../../components/svgicons/leftArrow";
import EmptyCart from "../../images/cart.png";
import { useNavigate } from "react-router-dom";
import Login from "../../views/Login/component";
import clearCartIcon from "../../images/clear-cart.png";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { USER_DATA } from "../../components/common/constant";
import { sellerDetails } from "../../components/common/utils";
const Cart = () => {
  const [clearCart, setClearCart] = useState(false);

  let cartItems = JSON.parse(
    localStorage.getItem("cart_" + sellerDetails()?._id)
  );
  const [cartItemsTemp, setCartItemsTemp] = useState(cartItems);
  // const [openLoginModel, setOpenLoginModel] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const navigate = useNavigate();

  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem(USER_DATA) || "");
    } catch (error) {
      return null;
    }
  };

  let totalMRP = 0;
  let totalDiscountPrice = 0;
  let totalAmount = 0;

  if (cartItems && cartItems !== undefined) {
    cartItems.forEach((item) => {
      totalMRP += item.quantity * item.catalogData.price;
      totalAmount += item.quantity * item.catalogData.discountedPrice;
      totalDiscountPrice = totalMRP - totalAmount;
    });
  }

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItemsTemp.findIndex((item) => item._id === itemId);
    if (newQuantity > parseInt(singleCatalogData.availableQuantity)) {
      alert(
        "Available Quantity is only " + singleCatalogData.availableQuantity
      );
    } else {
      if (itemIndex !== -1) {
        // Update the quantity of the item
        let updatedCartItems = [...cartItemsTemp];
        if (newQuantity === 0) {
          const arr = updatedCartItems.filter(function (item) {
            return item !== updatedCartItems[itemIndex];
          });
          updatedCartItems = arr;
        } else {
          updatedCartItems[itemIndex].quantity = newQuantity;
        }
        setCartItemsTemp(updatedCartItems);
        localStorage.setItem(
          "cart_" + sellerDetails()?._id,
          JSON.stringify(updatedCartItems)
        );
      }
    }
  };
  return (
    <>
      <Header cartItemLength={cartItems?.length} />
      {/* <div className="PageContent" style={{ position: "relative" }}>
                <div className="container-fluid p-0"> */}
      <div className="productSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center gap-5 py-3">
                <p className="m-0" style={{ cursor: "pointer" }}>
                  <LeftArrow
                    className="me-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  Back
                </p>
                <h6
                  className="m-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setClearCart(true);
                  }}
                >
                  {cartItemsTemp && cartItemsTemp.length > 0
                    ? "Clear Cart"
                    : ""}
                </h6>
              </div>
            </div>
          </div>
          {cartItemsTemp && cartItemsTemp.length > 0 ? (
            <div className="row">
              <div className="col-lg-8 mb-2">
                <div className="ProductList-grid cart-provider">
                  {/* <div className="CartproductView"> */}
                  {cartItemsTemp?.map((cartData) => {
                    return (
                      <div className="productCard" key={cartData._id}>
                        <div className="d-flex justify-content-between gap-3">
                          <div
                            className="productDetail"
                            style={{ cursor: "auto" }}
                          >
                            <div>
                              <h2 className="productTitle">
                                {cartData.catalogData.productName}
                              </h2>
                              {/* <p className="productDes">{cartData.catalogData.description.slice(0, 20)}</p> */}
                            </div>
                            <button
                              className="removebtn"
                              onClick={() => {
                                updateCartItemQuantity(
                                  cartData.catalogData._id,
                                  0,
                                  cartData.catalogData
                                );
                              }}
                            >
                              Remove
                            </button>

                            <div>
                              <div className="productRate">
                                <h1>
                                  ₹
                                  {cartData.quantity *
                                    cartData.catalogData.discountedPrice}
                                </h1>
                                <span className="line-thorugh">
                                  ₹
                                  {cartData.quantity *
                                    cartData.catalogData.price}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="productimage">
                            <div className="productImg">
                              <img
                                src={cartData.catalogData.productImages[0]}
                                alt="Product Catalog"
                              />
                            </div>
                            <div className="add--cart-btn">
                              <div
                                className="addtocart-qty"
                                style={{ position: "unset" }}
                              >
                                <div
                                  className="addtocart-button button-down minus"
                                  onClick={() => {
                                    updateCartItemQuantity(
                                      cartData.catalogData._id,
                                      cartData.quantity === 1
                                        ? 0
                                        : (cartData.quantity -= 1),
                                      cartData.catalogData
                                    );
                                  }}
                                >
                                  -
                                </div>
                                <input
                                  type="number"
                                  readOnly={true}
                                  className="addtocart-input"
                                  value={cartData.quantity}
                                />
                                <div
                                  className="addtocart-button button-up"
                                  onClick={() => {
                                    updateCartItemQuantity(
                                      cartData.catalogData._id,
                                      (cartData.quantity += 1),
                                      cartData.catalogData
                                    );
                                  }}
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* </div> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="priceDetails">
                  <table className="w-100">
                    <thead>
                      <tr className="text-start">
                        <th colSpan="2" style={{ color: "gray" }}>
                          Price Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Price</td>
                        <td className="text-end" style={{ fontWeight: 500 }}>
                          ₹{totalMRP}
                        </td>
                      </tr>
                      <tr className="border-0">
                        <td style={{ fontWeight: 500 }}>Discount</td>
                        <td
                          className="text-end"
                          style={{ color: "green", fontWeight: 500 }}
                        >
                          - ₹{totalDiscountPrice}
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px dashed #c9c9c9" }}>
                        <td>
                          <b>Total Amount</b>
                        </td>
                        <td className="text-end">
                          <b>₹{totalAmount}</b>
                        </td>
                      </tr>
                      {totalDiscountPrice > 0 && (
                        <tr style={{ borderTop: "1px dashed #c9c9c9" }}>
                          <th
                            colSpan="2"
                            style={{ color: "green", fontWeight: 500 }}
                          >
                            You will save ₹{totalDiscountPrice} on this order
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="my-3">
                  <button
                    className="btn Dark-btn w-100"
                    type="button"
                    onClick={() => {
                      getUserFromLocalStorage() &&
                      getUserFromLocalStorage() !== null &&
                      getUserFromLocalStorage() !== ""
                        ? navigate("/address")
                        : setModalIsOpen(true);
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="no-items">
              <img src={EmptyCart} alt="Empty Cart" />
              <h6>
                Looks like you don't have any <br /> products in your cart
              </h6>
              <p
                onClick={() => navigate(localStorage.getItem("slug"))}
                type="button"
                style={{ textDecoration: "underline" }}
              >
                Shop now
              </p>
            </div>
          )}
        </div>
      </div>
      {/* </div>
            </div> */}
      {modalIsOpen && (
        <Login
          isOpenFromCart={true}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
        ></Login>
      )}

      <Modal
        isOpen={clearCart}
        aria-labelledby="contained-modal-title-vcenter"
        toggle={() => {
          setClearCart(false);
        }}
        centered
        className="clear-cart clearcartmodal "
      >
        <div className="clearCart">
          <img src={clearCartIcon} />
          <h5 className="cart-title">Do you really want to clear the cart?</h5>
        </div>

        <ModalFooter className="border-0">
          <button
            onClick={() => {
              setClearCart(false);
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              localStorage.removeItem("cart_" + sellerDetails()?._id);
              setCartItemsTemp([]);
              setClearCart(false);
            }}
          >
            Clear
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Cart;
