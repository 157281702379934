import React, { useState } from "react";
import crossIcon from "../../images/cross-icon.svg";
import BlueProfile from "../../images/blue-profile.svg";
import BlueOrder from "../../images/blue-order.svg";
import { useNavigate } from "react-router-dom";
import BlueSignOut from "../../images/blue-signout.svg";
import BlueAddress from "../../images/blue-address.svg";
import API from "../../api";
import { SEARCH_CATALOG } from "../../api/apiList";
import Profile from "../../components/svgicons/Profile";
import searchIcon from "../../images/searchIcon.svg";
import ShoppingCart from "../../components/svgicons/ShoppingCart";
import { get } from "lodash";
import { copyToClipboard } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import HamburgerNew from "../../components/NewImages/hamburgerMenu.svg";
import MenuProfile from "../../components/NewImages/menu-profile-icon.svg";
import LogoutLogo from "../../images/logout-logo.png";
import DownArrow from "../../images/down-arrow.svg";
import Login from "../Login/component";

const Header = (props) => {
  const navigate = useNavigate();
  const [logout, setlogout] = useState(false);
  const [sortType, setSortType] = useState("");
  const userLogout = () => {
    const store = JSON.parse(localStorage.getItem("sellerDetails"));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("cart_" + store?._id);
    window.location.reload();
  };

  const token = localStorage.getItem("token") || "";

  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem("user") || "");
    } catch (error) {
      return null;
    }
  };

  const getSellerDetails = () => {
    try {
      return JSON.parse(localStorage.getItem("sellerDetails") || "");
    } catch (error) {
      return null;
    }
  };

  const sellerDetails = getSellerDetails();

  const userData = getUserFromLocalStorage();

  const [catalogueData, setCatalogData] = useState([]);

  let cartItemsLength = props.cartItemLength;

  const onChange = (searchText) => {
    console.log("HEADER : SEARCH DATA", searchText);
    if (searchText) {
      API.post(SEARCH_CATALOG, {
        search: searchText,
        sellerId: sellerDetails?._id,
      }).then((data) => {
        console.log("HEADER : SEARCH DATA", data.data);
        if (data?.data?.data.length > 0) {
          setCatalogData(data?.data?.data);
        } else {
          setCatalogData([]);
        }
      });
    } else {
      setCatalogData([]);
    }
  };
  const [categoryName, setCategoryName] = useState("");
  const [categoriesData, setCategoriesData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <header className="headerSection">
        <ToastContainer
          autoClose={300}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="container">
          <div className="headermain">
            {token && token !== "" ? (
              <div
                className="left--icon"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <img alt="Menu" src={HamburgerNew} className="header--menu" />
              </div>
            ) : (
              <div>
                <img
                  alt="Login"
                  src={HamburgerNew}
                  className="header--menu"
                  onClick={() => {
                    setModalIsOpen(true);
                  }}
                />
              </div>
            )}
            {/* <div className="contactDetail">
              <p
                className="m-0"
                onClick={() => {
                  navigate(localStorage.getItem("slug"));
                }}
              >
                <img
                  className="homeicon"
                  src={HomeIcon}
                  style={{ maxWidth: "20px" }}
                />
              </p>
              <p
                className="contactNo"
                onClick={() => {
                  copyToClipboard(sellerDetails?.phone);
                }}
              >
                {sellerDetails?.phone}
              </p>

              {sellerDetails?.whatsAppNumber && (
                <p
                  className="WhatsaapNo"
                  onClick={() => {
                    copyToClipboard(sellerDetails?.whatsAppNumber);
                  }}
                >
                  {"+91" + sellerDetails?.whatsAppNumber}
                </p>
              )}
            </div> */}
            <div className="d-flex align-items-center gap-3 w-100 justify-content-center">
              <div className="search-top">
                <div className="searchBar">
                  <div className="searchInput">
                    <button>
                      <img src={searchIcon} alt="Search Icon" />
                    </button>
                    <input
                      type="text"
                      placeholder="Search for a product"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  </div>
                  {catalogueData && catalogueData?.length > 0 ? (
                    <div className="searchDropdown">
                      {catalogueData?.map((data) => {
                        return (
                          <p
                            onClick={() => {
                              !props.func &&
                                navigate("/productDetails", {
                                  state: {
                                    catalogData: data,
                                    catalogDataArray: {},
                                  },
                                });
                              props.func && props.func(data);
                              props.func && setCatalogData([]);
                            }}
                          >
                            {data.productName}
                          </p>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div className="collectionFilter">
              <div className="filterItem">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setCategoryName(
                      e.target.value === "All Categories" ? "" : e.target.value
                    );
                  }}
                >
                  <option>All Categories</option>
                  {categoriesData.data !== undefined &&
                    categoriesData.data.map((data) => {
                      return <option>{data.title}</option>;
                    })}
                </select>
                <img src={DownArrow} className="downArrow" />
              </div>
              <div className="filterItem">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSortType(e.target.value);
                  }}
                >
                  <option>Sort</option>
                  <option>All Products</option>
                  <option>Price: Low To High</option>
                  <option>Price: High To Low</option>
                </select>
                <img src={DownArrow} className="downArrow" />
              </div>
            </div> */}
            </div>
            <div className="headerRight">
              {/* <div class="popover__wrapper">
                <h2 class="popover__title m-0">
                  {token && token !== "" ? (
                    <div className="profileImg">
                      <Profile style={{ maxWidth: "28px" }} />
                      {userData?.name ? userData?.name : "Hello User"}
                    </div>
                  ) : (
                    <div className="profileDetails">
                      <a href="/login">
                        <Profile className="Icon" />
                        Sign In
                      </a>
                    </div>
                  )}
                </h2>
                {token && token !== "" && (
                  <div class="popover__content">
                    <ul className="profile-editor">
                      <li>
                        <a href="/buyerprofile">
                          <img
                            src={BlueProfile}
                            className="me-2"
                            alt="BlueProfile"
                          />
                          Your Account
                        </a>
                      </li>
                      <li>
                        <a href="/order">
                          <img
                            src={BlueOrder}
                            className="me-2"
                            alt="BlueOrder"
                          />
                          Your Orders
                        </a>
                      </li>
                      <li>
                        <a href="/manageAddress">
                          <img
                            src={BlueAddress}
                            className="me-2"
                            alt="BlueAddress"
                          />
                          Your Addresses
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          setlogout(true);
                        }}
                        style={{ fontSize: "14px" }}
                      >
                        <img
                          src={BlueSignOut}
                          className="me-2"
                          alt="BlueSignOut"
                        />
                        Logout
                      </li>
                    </ul>
                  </div>
                )}
              </div> */}
              {/* <Popover content={token && token !== "" ? content : null} trigger="hover" overlayInnerStyle={{ width: "160px", marginRight: "20px" }} >
                {token && token !== "" ? <div className="profileImg"><Profile style={{ maxWidth: "28px" }} />{userData?.name ? userData?.name : "Hello User"}</div> : <div className="profileDetails"><a href="/login">
                  <Profile className="Icon" />Sign In
                </a></div>
                }
              </Popover> */}
              <span className="profileDetails">
                <a href="/cart">
                  <ShoppingCart className="Icon" />
                  Cart
                </a>
                {cartItemsLength && cartItemsLength > 0 ? (
                  <div className="cartCounter">{cartItemsLength}</div>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </div>
          {modalIsOpen && (
            <Login
              isOpenFromCart={false}
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
            ></Login>
          )}
        </div>
      </header>

      {/* <div className="mobileSearch">
        <div className="container">
          <div className="searchBar">
            <div className="searchInput">
              <input
                type="text"
                placeholder="Search for a product"
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
              <button>
                <img src={searchIcon} alt="Search Icon" />
              </button>
            </div>
            {catalogueData && catalogueData?.length > 0 ? (
              <div className="searchDropdown">
                {catalogueData?.map((data) => {
                  return (
                    <p
                      onClick={() => {
                        !props.func &&
                          navigate("/productDetails", {
                            state: { catalogData: data, catalogDataArray: {} },
                          });
                        props.func && props.func(data);
                        props.func && setCatalogData([]);
                      }}
                    >
                      {data.productName}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div> */}

      <Modal
        isOpen={logout}
        aria-labelledby="contained-modal-title-vcenter"
        toggle={() => {
          setlogout(false);
        }}
        centered
        className="clearcartmodal"
      >
        <div className="confirmLogout">
          <img src={LogoutLogo} alt="Logout" />
          <h1 className="confirm-subtitle">
            Thank you for visiting {sellerDetails?.storeName}
          </h1>
        </div>
        <ModalFooter className="border-0 flex-column">
          <h5 className="confirmtitle">Are you sure you want to logout?</h5>
          <div className="d-flex justify-content-center gap-2">
            <button
              onClick={() => {
                setlogout(false);
              }}
            >
              No
            </button>
            <button
              onClick={() => {
                userLogout();
              }}
            >
              Yes
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <div
        class="offcanvas offcanvas-start side-hamburger"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header justify-content-end">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div className="menu-title">
            <h1>
              <img src={MenuProfile} className="me-2" />
              {userData?.name ?? ""}
            </h1>
          </div>
          <ul className="menu--list">
            <li>
              <a class="Menu--item" href="/buyerprofile">
                Profile Information
              </a>
            </li>
            <li>
              <a class="Menu--item" href="/manageAddress">
                Manage Address
              </a>
            </li>
            <li>
              <a class="Menu--item" href="/order">
                Order
              </a>
            </li>
          </ul>
          <div className="logout_menu" data-bs-dismiss="offcanvas">
            <div
              className="menu-logout-list"
              onClick={() => {
                setlogout(true);
              }}
            >
              Logout
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
