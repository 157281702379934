
import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
  loading: false,
  hasErrors: false,
  status: false,
  data: [],
  message: ""
};

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = action.payload;
    },
    getCatalogSuccess: (state, action) => {
      // console.log("getCatalogSuccess : action", action.payload)
      state.data = action.payload;
      // console.log("SUCESS getCatalogSuccess ", action.payload)

      state.loading = false;
      state.hasErrors = false;
      state.status = true;
    },
    getCatalogFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.status = false;
    },
  },
});

export const { startLoading, getCatalogSuccess, getCatalogFailure } = catalogSlice.actions;

export default catalogSlice.reducer;

