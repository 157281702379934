import React, { useState } from "react";
import Header from "../Header/component";
import LeftArrow from "../../components/svgicons/leftArrow";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import API from "../../api";
import { CREATE_ORDER_OFFLINE } from "../../api/apiList";
import { handleError } from "../../components/common/utils";
import Loader from "../../components/common/loader";
import ThankYouModal from "../../components/common/ThankyouModal";
import { USER_DATA } from "../../components/common/constant";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userAddress = location.state.userAddress;
  const estimationData = location.state.estimationData;

  let sellerDetails = JSON.parse(localStorage.getItem("sellerDetails"));

  let userData = {};
  let cartItems = [];
  try {
    userData = JSON.parse(localStorage.getItem(USER_DATA));
    cartItems = JSON.parse(localStorage.getItem("cart_" + sellerDetails?._id));
  } catch (error) { }

  const [cartItemsTemp, setCartItemsTemp] = useState(cartItems);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [openThankPopup, setOpenThankPopup] = useState(false);

  const isPrepaidAndBoth =
    get(sellerDetails, "paymentMode", "") === "both" ||
    get(sellerDetails, "paymentMode", "") === "prepaid";
  const isCodAndBoth =
    get(sellerDetails, "paymentMode", "") === "both" ||
    get(sellerDetails, "paymentMode", "") === "cod";
  const [paymentMode, setPaymentMode] = useState(
    isPrepaidAndBoth ? "KikoPayment" : "SelfPayment"
  );

  let totalDiscountedPrice = 0;
  let totalWeight = 0;

  if (cartItems && cartItems !== undefined) {
    cartItems.forEach((item) => {
      totalDiscountedPrice += item.quantity * item.catalogData.discountedPrice;
      if (
        item?.catalogData.weightUnit == "GRAMS" ||
        item?.catalogData.weightUnit == "ML"
      ) {
        totalWeight += (item?.catalogData.weight / 1000) * item.quantity;
      } else {
        totalWeight += item?.catalogData.weight * item?.quantity;
      }
    });
  }

  let cartItemsTempOrder = [];
  if (cartItemsTemp && cartItemsTemp !== undefined) {
    cartItemsTemp.forEach((item) => {
      let cartData = {
        id: item._id,
        quantity: { count: item.quantity },
        price: parseInt(item.catalogData.discountedPrice),
      };
      cartItemsTempOrder.push(cartData);
    });
  }

  const createOrder = () => {
    setCheckoutLoading(true);
    // const locationData = JSON.parse(localStorage.getItem("location"));
    const user = JSON.parse(localStorage.getItem(USER_DATA));
    sellerDetails = JSON.parse(localStorage.getItem("sellerDetails"));
    const shippingAmt = get(estimationData, "price", 0);
    const settlementAmount =
      parseFloat(totalDiscountedPrice) + parseFloat(shippingAmt);
    const obj = {
      websiteOrderServiceability: get(
        sellerDetails,
        "websiteOrderServiceability",
        {}
      ),
      settlementData: {
        amount: settlementAmount,
        status: "pending",
      },
      agentId: get(sellerDetails, "_id", ""),
      buyerName: get(userAddress, "contactName", ""),
      buyerPhoneNumber: get(user, "mobile", ""),
      orderAmount: totalDiscountedPrice,
      orderExpiresTime: 1440,
      orderMode: "Offline",
      orderPaymentMode: paymentMode,
      orderDeliveryMode: null,
      orderQuantity: 0,
      productItemList: [],
      totalWeight: totalWeight,
      vendorId: get(sellerDetails, "_id", ""),
      addressAddedBy: "buyer",
      orderStatus:
        paymentMode === "SelfPayment" ? "payment-completed" : "drafted",
      createdBy: get(userAddress, "user_id", ""),
      shippingAmount: get(estimationData, "price", ""),
      orderDescription: "",
      coinAmount: "0",
      freeDelivery: false,
      actualShippingAmount: get(estimationData, "price", 0),
      shippingAmountDiscount: 0,
      cartItem: cartItemsTempOrder,
      userAddress: {
        city: get(userAddress, "city", ""),
        state: get(userAddress, "state", ""),
        zipcode: get(userAddress, "zipcode", ""),
        contactName: get(userAddress, "contactName", ""),
        latitude: get(userAddress, "region.latitude", ""),
        longitude: get(userAddress, "region.longitude", ""),
        contactPhone: get(userAddress, "contactPhone", ""),
        addressType: "HOME",
        address_line1: get(userAddress, "city", ""),
        landmark: get(userAddress, "landmark", ""),
        address_line2: get(userAddress, "address_line2", ""),
      },
      orderLocation: {
        city: "",
        state: "",
        zipcode: "",
        contactName: "",
        latitude: 0.0,
        longitude: 0.0,
        contactPhone: "",
        addressType: "",
        address_line1: "",
        landmark: "",
        address_line2: "",
        runningOrder: false,
      },
      createdFrom: "microwebsite",
    };
    console.log("CHECKOUT: ORDER ", obj);
    API.post(CREATE_ORDER_OFFLINE, obj)
      .then(({ data }) => {
        console.log("CHECKOUT: ORDER RESPONSE 1 ", data);
        setCheckoutLoading(false);
        if (data.success) {
          const orderDetails = data.data;
          if (paymentMode === "SelfPayment") {
            setOpenThankPopup(true);
          } else {
            console.log("CHECKOUT: ORDER RESPONSE 2 ", orderDetails);
            let shippingAmount = parseFloat(
              get(estimationData, "price", 0) + totalDiscountedPrice
            );
            navigate("/preRazorpay", {
              state: { orderDetails: orderDetails, orderAmount: shippingAmount },
            });
          }
        } else if (data.orderAlreadyInProcess) {
          alert("Already order is in progress");
        } else if (data?.outOfStock) {
          alert(data?.message);
        }
      })
      .catch((error) => {
        setCheckoutLoading(false);
        handleError(error);
      });
  };
  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItemsTemp?.findIndex((item) => item._id === itemId);
    if (newQuantity > parseInt(singleCatalogData.availableQuantity)) {
      alert(
        "Available Quantity is only " + singleCatalogData.availableQuantity
      );
    } else {
      if (itemIndex !== -1) {
        // Update the quantity of the item
        const updatedCartItems = [...cartItemsTemp];
        updatedCartItems[itemIndex].quantity = newQuantity;
        setCartItemsTemp(updatedCartItems);
        localStorage.setItem(
          "cart_" + sellerDetails?._id,
          JSON.stringify(updatedCartItems)
        );
      }
    }
  };

  return (
    <>
      <Header cartItemLength={cartItems?.length} />
      <div className="productSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 py-3">
              <div className="d-flex align-items-center gap-2">
                <a style={{ color: "#000", textDecoration: "none" }}>
                  <LeftArrow onClick={() => {
                    navigate(-1);
                  }} />
                </a>
                <h3 className="m-0">Checkout</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="cartView-price p-0 mb-3">
                <div className="step-completed">
                  <div className="d-flex align-items-start justify-content-between">
                    <div>
                      <p
                        className="type_label"
                        style={{ textTransform: "uppercase" }}
                      >
                        Delivering to:
                      </p>
                      <p className="type_label">
                        <b>{userAddress.contactName}</b>
                      </p>
                      <p className="type_label">
                        {userData.mobile}, <span>{userData.email}</span>
                      </p>
                      <p className="type_label">
                        {get(userAddress, "address_line2", "")},<br />
                        <span>
                          {get(userAddress, "zipcode", "")}{" "}
                          {get(userAddress, "city", "")}
                        </span>
                      </p>
                    </div>
                    <button
                      className="btn Dark-btn justify-content-center"
                      type="button"
                      onClick={() => {
                        navigate("/address");
                      }}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
              <div className="cartView-price mb-4">
                <div className="cartView-header">
                  <h4 className="m-0">Update Cart</h4>
                </div>
                <div className="cartView-body checkoutCards">
                  <div className="ProductList-grid">
                    {cartItems?.map((item) => {
                      return (
                        <div className="productCard">
                          <div className="d-flex justify-content-between gap-3">
                            <div className="productDetail">
                              <div>
                                <h2 className="productTitle">
                                  {item.catalogData.productName}
                                </h2>
                              </div>
                              <div>
                                <div className="productRate">
                                  <h1>
                                    ₹
                                    {`${item.quantity *
                                      item.catalogData.discountedPrice
                                      }`}
                                  </h1>
                                  <span className="line-thorugh">
                                    ₹
                                    {`${item.quantity * item.catalogData.price
                                      }`}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="productimage">
                              <div className="productImg">
                                <img
                                  src={item.catalogData.productImages[0]}
                                  alt="Product Catalog"
                                />
                              </div>
                              <div className="add--cart-btn">
                                <div
                                  className="addtocart-qty"
                                  style={{ position: "unset" }}
                                >
                                  <div
                                    class="addtocart-button button-down minus"
                                    onClick={() => {
                                      updateCartItemQuantity(
                                        item.catalogData._id,
                                        item.quantity === 1
                                          ? item.quantity
                                          : (item.quantity -= 1),
                                        item.catalogData
                                      );
                                    }}
                                  >
                                    -
                                  </div>
                                  <input
                                    type="number"
                                    class="addtocart-input"
                                    value={item.quantity}
                                  />
                                  <div
                                    class="addtocart-button button-up"
                                    onClick={() => {
                                      updateCartItemQuantity(
                                        item.catalogData._id,
                                        (item.quantity += 1),
                                        item.catalogData
                                      );
                                    }}
                                  >
                                    +
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="container-fluid p-0">
                <div className="cartView-price mb-3">
                  <div className="cartView-header">
                    <h4 className="m-0">Price Details</h4>
                  </div>
                  <div className="cartView-card-footer">
                    <div className=" py-1 d-flex align-items-center">
                      <p className="cartView-card-body">Sub Total</p>
                      <div className="ms-auto d-flex align-items-center">
                        <p className="cartView-sub-total">
                          <span className="me-1">₹</span>
                          {totalDiscountedPrice}
                        </p>
                      </div>
                    </div>
                    <div className="py-1 d-flex align-items-center">
                      <p className="cartView-card-body">Delivery Charges</p>
                      <div className="ms-auto d-flex align-items-center">
                        <p className="cartView-sub-total">
                          <span className="me-1">₹</span>
                          {get(estimationData, "price", 0)}
                        </p>
                      </div>
                    </div>
                    <div className="py-1 d-flex align-items-center">
                      <p className="cartView-card-body">
                        <b>Total</b>
                      </p>
                      <div className="ms-auto d-flex align-items-center">
                        <p className="cartView-sub-total">
                          <span className="me-1">₹</span>
                          {totalDiscountedPrice +
                            get(estimationData, "price", 0)}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center gap-5 pt-3">
                      {isPrepaidAndBoth && (
                        <label>
                          <input
                            defaultChecked={true}
                            type="radio"
                            name="paymentMode"
                            className="me-2 prepaid-checkbox"
                            onClick={() => {
                              setPaymentMode("KikoPayment");
                            }}
                          />
                          Prepaid
                        </label>
                      )}
                      {isCodAndBoth && (
                        <label>
                          <input
                            defaultChecked={!isPrepaidAndBoth}
                            type="radio"
                            name="paymentMode"
                            className="me-2"
                            onClick={() => {
                              setPaymentMode("SelfPayment");
                            }}
                            style={{ position: "relative" }}
                          />
                          Cash on Delivery
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="p-3 button-loader">
                    <button
                      disabled={checkoutLoading}
                      className="btn Dark-btn justify-content-center w-100"
                      type="button"
                      onClick={() => {
                        createOrder();
                      }}
                    >
                      {checkoutLoading
                        ? ""
                        : paymentMode === "SelfPayment"
                          ? "Place Order"
                          : "Proceed to pay"}
                    </button>
                    <div className="dotLoader">
                      {checkoutLoading && <Loader />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openThankPopup && (
        <ThankYouModal
          orderPaymentMode={paymentMode}
          orderAmount={parseFloat(
            get(estimationData, "price", 0) + totalDiscountedPrice
          )}
          openModal={openThankPopup}
          setOpenModal={setOpenThankPopup}
        />
      )}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};
export default Checkout;
