import React, { useEffect, useState } from "react";
import Header from "../Header/component";
import ProfilePlacehoder from "../../images/profile_placeholder.png";
import LeftArrow from "../../components/svgicons/leftArrow";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../api";
import { GET_ORDER_DETAILS } from "../../api/apiList";
import { get } from "lodash";
import { handleError } from "../../components/common/utils";
import Loader from "../../components/common/loader";

const OrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoading(true);
    await API.get(GET_ORDER_DETAILS + location.state.orderId)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          setOrderDetails(get(data, "data", ""));
          console.log("ORDER DETAILS : DATA ", data);
        }
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <div className="orderDetails">
        <div className="container">
          {loading ? (
            <div className="dotLoader">
              <Loader />
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12 py-3">
                <div className="d-flex align-items-center gap-2">
                  <LeftArrow
                    className="me-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <h5 className="m-0">Order Details</h5>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title flex-grow-1 mb-0">
                      Order ID : {orderDetails?.orderId}
                    </h5>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive table-card">
                      <table className="table table-nowrap align-middle table-borderless mb-0">
                        <thead className="table-light text-muted">
                          <tr>
                            <th scope="col">Product Details</th>
                            <th scope="col">Item Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col" class="text-end">
                              Total Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderDetails?.cartItem.map((cartData) => {
                            return (
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    {/* <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
                                                                        <img src={cartData?.id?.productImages[0]} className="img-fluid d-block" />
                                                                    </div> */}
                                    <div className="productImgOrderDetails">
                                      <img
                                        src={cartData?.id?.productImages[0]}
                                        alt="Product Catalog"
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <h5 class="product-title">
                                        {cartData?.id?.productName}
                                      </h5>
                                      <p class="text-muted mb-0">
                                        Weight:{" "}
                                        <span class="fw-medium">
                                          {cartData?.id?.weight +
                                            cartData?.id?.weightUnit}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>{cartData?.price}</td>
                                <td>{cartData?.quantity?.count}</td>
                                <td className="fw-medium text-end">
                                  {cartData?.quantity?.count * cartData?.price}
                                </td>
                              </tr>
                            );
                          })}
                          <tr className="border-top border-top-dashed">
                            <td colspan="2"></td>
                            <td colspan="3" className="fw-medium p-0">
                              <table className="table total-amount table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <td>Delivery charges:</td>
                                    <td class="text-end">
                                      {orderDetails?.shippingAmount}
                                    </td>
                                  </tr>
                                  <tr className="border-top border-top-dashed">
                                    <th scope="row">Total (INR) :</th>
                                    <th class="text-end">
                                      {orderDetails?.shippingAmount +
                                        orderDetails?.orderAmount}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title flex-grow-1 mb-0">
                      Order Status
                    </h5>
                  </div>
                  <div className="card-body">
                    {/* <div className="profile-timeline">
                      {orderDetails?.orderStatus}
                    </div> */}
                    <span
                      className={
                        orderDetails?.orderStatus === "order-cancelled"
                          ? "badge-soft-danger text-uppercase"
                          : "badge-soft-secondary text-uppercase"
                      }
                    >
                      {orderDetails?.deliveryVendorStatus &&
                      orderDetails?.orderStatus !== "order-cancelled"
                        ? orderDetails?.deliveryVendorStatus ===
                          "parcel_delivered"
                          ? "Parcel Delivered"
                          : "Parcel Picked Up"
                        : orderDetails?.orderPaymentMode === "SelfPayment"
                        ? orderDetails?.orderStatus === "payment-completed"
                          ? "ORDER PLACED"
                          : orderDetails?.orderStatus
                        : orderDetails?.orderStatus}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Store Details</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <div className="d-flex align-items-center">
                          <div className="profileImg">
                            <img
                              style={{ width: 150, height: 150 }}
                              src={
                                orderDetails?.vendorId?.storeLogo
                                  ? orderDetails?.vendorId?.storeLogo
                                  : ProfilePlacehoder
                              }
                              alt="User"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="customername">
                              {orderDetails?.vendorId?.storeName}
                            </h6>
                            <p className="text-muted mb-0">
                              {" "}
                              {orderDetails?.vendorId?.name}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="contact-details">
                        <p className="m-0">{orderDetails?.vendorId?.phone}</p>
                      </li>
                      <li className="contact-details">
                        <p className="m-0">
                          {orderDetails?.vendorId?.email
                            ? orderDetails?.vendorId?.email
                            : "N/A"}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="card">
                            <div className="card-header">
                                    <h5 className="card-title address mb-0"> Billing Address</h5>
                            </div>
                            <div className="card-body">
                                <ul className="list-unstyled mb-0">
                                    <li>Rajiv Singh</li>
                                    <li>Building: 15/11</li>
                                    <li>Locality: KG Halli, D' Souza Layout, Ashok Nagar, Bengaluru, Karnataka 560001, India</li>
                                    <li>Pincode: 560001</li>
                                    <li>City: Bengaluru</li>
                                    <li>Karnataka, India</li>
                                </ul>
                            </div>
                        </div> */}
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title address mb-0">
                      {" "}
                      Shipping Address
                    </h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-unstyled mb-0">
                      <li>{orderDetails?.userId?.name}</li>
                      <li>
                        Locality: {orderDetails?.userAddress?.address_line2}
                      </li>
                      <li>Pincode: {orderDetails?.userAddress?.zipcode}</li>
                      <li>City: {orderDetails?.userAddress?.city}</li>
                      <li>{orderDetails?.userAddress?.state}</li>
                    </ul>
                  </div>
                </div>
                {/* <div className="card Payment-Details">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Payment Details</h5>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-2">
                                        <p class="text-muted mb-0">Transaction ID:</p>
                                        <h6 class="mb-0 ms-2 fw-normal" >1689259086373</h6>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <p class="text-muted mb-0">Payment Mode:</p>
                                        <h6 class="mb-0 ms-2 fw-normal" >PAID</h6>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <p class="text-muted mb-0">Total Amount:</p>
                                        <h6 class="mb-0 ms-2 fw-normal">INR 599.00</h6>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default OrderDetails;

// {
//     "orderStatus": "order-cancelled",
//         "deliveryVendorStatus": "",
//             "orderRoomId": "",
//                 "orderAmount": 400,
//                     "orderDiscount": 0,
//                         "payableAmount": 0,
//                             "coinUsed": 0,
//                                 "maxDiscount": 0,
//                                     "shippingAmount": 531,
//                                         "orderDescription": "",
//                                             "orderQuantity": 0,
//                                                 "orderDeliveryMode": null,
//                                                     "orderPaymentMode": "KikoPayment",
//                                                         "orderMode": "Offline",
//                                                             "dunzoTransactionId": "",
//                                                                 "razorPayOrderId": "order_MIScFj9yIdUGFo",
//                                                                     "razorPayTransactionId": "pay_MIScTy8VxS2RPf",
//                                                                         "currency": "INR",
//                                                                             "paymentGateway": "razorpay",
//                                                                                 "coinAmount": "0",
//                                                                                     "rewardType": "",
//                                                                                         "coinOrderId": "",
//                                                                                             "couponCode": "",
//                                                                                                 "witdrawalStatus": "Pending",
//                                                                                                     "trackingUrl": "",
//                                                                                                         "kikoDeliveryStatus": "",
//                                                                                                             "orderExpiresTime": 60,
//                                                                                                                 "orderLink": "https://s.kiko.live/oo?id=kiko_3213",
//                                                                                                                     "buyerPhoneNumber": "9009120913",
//                                                                                                                         "buyerName": "44",
//                                                                                                                             "isRefund": true,
//                                                                                                                                 "deliveryRazorPayOrderId": "order_MIScCEltaonAeq",
//                                                                                                                                     "deliveryRazorPayTransactionId": null,
//                                                                                                                                         "actualShippingAmount": 531,
//                                                                                                                                             "shippingAmountDiscount": 0,
//                                                                                                                                                 "createdFrom": "microwebsite",
//                                                                                                                                                     "_id": "64c12b8e66f8653729d78e55",
//                                                                                                                                                         "agentId": {
//         "photo": "",
//             "phone": "+917879338233",
//                 "_id": "648170d3ffeacc1f96700ac6",
//                     "name": "vishal yadav"
//     },
//     "productItemList": [],
//         "totalWeight": 12,
//             "vendorId": {
//         "storeAddress": {
//             "pincode": 400080,
//                 "address1": "12",
//                     "address2": "Shop No.6,Shiv Ashish Bluding ,M.G.Road, Pacha Rasta, Mulund West, Asha Nagar, Mulund West, Mumbai, Maharashtra 400080, India",
//                         "nearBy": "",
//                             "state": "Maharashtra",
//                                 "city": "Mumbai",
//                                     "country": "India",
//                                         "latitude": 19.1726,
//                                             "longitude": 72.9414,
//                                                 "contactPersonName": "vishal yadav",
//                                                     "contactPersonMobile": "7879338233"
//         },
//         "photo": "",
//             "phone": "+917879338233",
//                 "mobile": "7879338233",
//                     "rPointBalance": 5,
//                         "discountPercent": 5,
//                             "maxDiscount": 0,
//                                 "walletBalance": 1000,
//                                     "_id": "648170d3ffeacc1f96700ac6",
//                                         "name": "vishal yadav",
//                                             "storeLogo": "https://kikonewapi.s3.ap-south-1.amazonaws.com/uploads/user_images/SDmnE-Csw.png",
//                                                 "storeName": "vishal shop"
//     },
//     "addressAddedBy": "6386154b42674fabf7bdabf4",
//         "createdBy": "6386154b42674fabf7bdabf4",
//             "freeDelivery": false,
//                 "cartItem": [
//                     {
//                         "quantity": {
//                             "count": 1
//                         },
//                         "id": {
//                             "productImages": [
//                                 "https://d1yd3a2ik8ypqd.cloudfront.net/uploads/user_images/w0ySsbWwT.png"
//                             ],
//                             "_id": "6493f56b465e475e1f5e9a82",
//                             "productName": "vishal test",
//                             "weight": 12,
//                             "weightUnit": "kg"
//                         },
//                         "price": 400
//                     }
//                 ],
//                     "userAddress": {
//         "city": "Mumbai",
//             "state": "Maharashtra",
//                 "zipcode": "400080",
//                     "contactName": "44",
//                         "latitude": "18.9690247",
//                             "longitude": "72.8205292",
//                                 "contactPhone": "+917689965008",
//                                     "addressType": "HOME",
//                                         "address_line1": "Mumbai",
//                                             "landmark": "",
//                                                 "address_line2": "Mumbai Central, Mumbai, Maharashtra, India"
//     },
//     "orderLocation": {
//         "city": "",
//             "state": "",
//                 "zipcode": "",
//                     "contactName": "",
//                         "latitude": 0,
//                             "longitude": 0,
//                                 "contactPhone": "",
//                                     "addressType": "",
//                                         "address_line1": "",
//                                             "landmark": "",
//                                                 "address_line2": "",
//                                                     "runningOrder": false
//     },
//     "userId": {
//         "photo": "",
//             "phone": "+919009120913",
//                 "mobile": "9009120913",
//                     "_id": "6386154b42674fabf7bdabf4",
//                         "name": "Vijendra P"
//     },
//     "orderTracking": [
//         {
//             "_id": "64c12b8e66f8653729d78e56",
//             "status": "drafted",
//             "createdAt": "2023-07-26T14:19:58.519Z"
//         },
//         {
//             "_id": "64c12ba266f8653729d78e6e",
//             "status": "payment-completed",
//             "createdAt": "2023-07-26T14:20:18.272Z",
//             "remarks": "Payment captured by razor pay webhook."
//         },
//         {
//             "_id": "64c1f68c66f8653729d79423",
//             "status": "order-cancelled",
//             "createdAt": "2023-07-27T04:46:04.470Z"
//         }
//     ],
//         "orderId": "kiko_3213",
//             "paymentTracking": [
//                 {
//                     "transactionId": "",
//                     "_id": "64c12ba266f8653729d78e64",
//                     "status": "authorized",
//                     "createdAt": "2023-07-26T14:20:18.119Z",
//                     "remarks": "Kiko Seller Payment"
//                 },
//                 {
//                     "transactionId": "",
//                     "_id": "64c12ba466f8653729d78e74",
//                     "status": "captured",
//                     "createdAt": "2023-07-26T14:20:20.067Z",
//                     "remarks": "Kiko Seller Payment"
//                 }
//             ],
//                 "kikoDeliveryStatusTracker": [],
//                     "createdAt": "2023-07-26T14:19:58.523Z",
//                         "updatedAt": "2023-07-27T04:46:06.875Z",
//                             "__v": 0,
//                                 "transactionFee": 0,
//                                     "cancelledBy": "kikouser",
//                                         "refund": {
//         "id": "rfnd_MIhN4sUeQZC63U",
//             "entity": "refund",
//                 "amount": 93100,
//                     "currency": "INR",
//                         "payment_id": "pay_MIScTy8VxS2RPf",
//                             "notes": [],
//                                 "receipt": "lkko9dj0",
//                                     "acquirer_data": {
//             "rrn": null
//         },
//         "created_at": 1690433166,
//             "batch_id": "",
//                 "status": "processed",
//                     "speed_processed": "normal",
//                         "speed_requested": "normal"
//     },
//     "roomDetails": null,
//         "coinRupeesRatio": 20,
//             "totalAmount": 16,
//                 "totalRedeemAvailable": 3424
// }
