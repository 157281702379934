import React from "react";
import KikoLogo from "../../../images/kiko--logo.svg";
import Facebook from "../../../images/facebook.svg";
import Insta from "../../../images/instagram.svg";
import Youtube from "../../../images/youtube.svg";
import LinkedIn from "../../../images/linkedin11.svg";
import AppStore from "../../../images/appstore.png";
import PlayStore from "../../../images/playstore.png";

const Disclaimer = (props) => {
    return (
        <>
            <header class="page-header">
                <div class="container">
                    <span class="ltx-before"></span>
                    <h1>Disclaimer</h1><ul class="breadcrumbs" typeof="BreadcrumbList" vocab="https://schema.org/">
                        <li class="home"><span property="itemListElement" typeof="ListItem"><a property="item" typeof="WebPage" title="Go to Home." href="https://kiko.live" class="home"><span property="name">Home</span></a><meta property="position" content="1" /></span></li>
                        <li class="post post-page current-item"><span property="itemListElement" typeof="ListItem"><span property="name">Disclaimer</span><meta property="position" content="2" /></span></li>
                    </ul>
                    <span class="ltx-after"></span>
                    <div class="ltx-header-icon"></div>

                </div>
            </header>
            <div class="container main-wrapper">

                <div class="inner-page text-page margin-default">
                    <div class="row justify-content-center">
                        <div class="col-xl-9 col-lg-8 col-md-12 col-xs-12 text-page">
                            <article id="post-9901" class="post-9901 page type-page status-publish hentry">
                                <div class="entry-content clearfix" id="entry-div">

                                    <p><strong><u>Updated at 2021-05-07</u></strong></p>
                                    <p>Kiko Live hereby grants you access to www.kiko.media and www.kiko.live (“the Website”) and invites you to purchase the services offered here.</p>
                                    <p><strong>Definitions And Key Terms</strong></p>
                                    <p>To help explain things as clearly as possible in this Disclaimer, every time any of these terms are referenced, are strictly defined as:</p>
                                    <ol>
                                        <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                                        <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Smooth Tag Technologies Pvt Ltd. , (A 603 EMROLD COURT INDORE Indore MP 452001 IN) that is responsible for your information under this Disclaimer.</li>
                                        <li>Service: refers to the service provided by Kiko Live as described in the relative terms (if available) and on this platform.</li>
                                        <li>Website: Kiko Live.”’s” site, which can be accessed via this URL: www.kiko.media or www.kiko.live</li>
                                        <li>You: a person or entity that is registered with Kiko Live to use the Services.</li>
                                    </ol>
                                    <p><strong>Limited Liability</strong></p>
                                    <p>Kiko Live endeavours to update and/or supplement the content of the website/app on a regular basis. Despite our care and attention, content may be incomplete and/or incorrect.</p>
                                    <p>The materials offered on the website/app are offered without any form of guarantee or claim to their correctness. These materials can be changed at any time without prior notice from Kiko Live.</p>
                                    <p>Particularly, all prices on the website/app are stated subject to typing and programming errors. No liability is assumed for the implications of such errors. No agreement is concluded on the basis of such errors.</p>
                                    <p>Kiko Live shall not bear any liability for hyperlinks to websites or services of third parties included on the website/app. From our website/app, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone ‘bad’.</p>
                                    <p>Please be also aware that when you leave our website/app, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their “Terms of Service” before engaging in any business or uploading any information.</p>
                                    <p><strong>Errors and Omissions Disclaimer</strong></p>
                                    <p>Kiko Live is not responsible for any content, code or any other imprecision.</p>
                                    <p>Kiko Live does not provide warranties or guarantees.</p>
                                    <p>In no event shall Kiko Live be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. Kiko Live reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</p>
                                    <p>You must not use the app in any way that causes, or is likely to cause, the app or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not Kiko Live app, are responsible for all electronic communications and content sent from your mobile to us and you must use the app for lawful purposes only. You must not use the app for any of the following:</p>
                                    <p>for fraudulent purposes, or in connection with a criminal offense or other unlawful activity</p>
                                    <p>to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that contains nudity or sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any “spam</p>
                                    <p>to cause annoyance, inconvenience or needless anxiety</p>
                                    <p>Kiko Live platform strictly does not allow nudity or sexual content when allowing users to use their cameras or gallery to upload photos and videos. Any such content may be flagged by users and will be removed immediately.</p>
                                    <p><strong>General Disclaimer</strong></p>
                                    <p>The Kiko Live Service and its contents are provided “as is” and “as available” without any warranty or representations of any kind, whether express or implied. Kiko Live is a distributor and not a publisher of the content supplied by third parties; as such, Kiko Live exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the Kiko Live Service. Without limiting the foregoing, Kiko Live specifically disclaims all warranties and representations in any content transmitted on or in connection with the Kiko Live Service or on sites that may appear as links on the Kiko Live Service, or in the products provided as a part of, or otherwise in connection with, the Kiko Live Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by Kiko Live or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, Kiko Live does not warrant that the Kiko Live Service will be uninterrupted, uncorrupted, timely, or error-free.</p>
                                    <p><strong>Copyright Disclaimer</strong></p>
                                    <p>All intellectual property rights concerning these materials are vested in Kiko Live. Copying, distribution and any other use of these materials is not permitted without the written permission of Kiko Live, except and only to the extent otherwise provided in regulations of mandatory law (such as the right to quote), unless otherwise stated for certain materials.</p>
                                    <p><strong>Legal Disclosure</strong></p>
                                    <p>The materials, any comments or information provided by Kiko Live are for educational purposes only and nothing conveyed or provided should be considered legal, accounting or tax advice. You are responsible for whatever you may do with the information you obtain from Kiko Live. As such, by visiting and using this website/app you acknowledge and agree that you have been assessed by a qualified law expert (i.e your attorney) who has given you consent to take part in any legal activity. Please contact your own attorney, accountant or tax professional with any specific questions you have related to the information provided that are of legal, accounting or tax nature.</p>
                                    <p><strong>Advertising Disclosure</strong></p>
                                    <p>This website/app may contain third party advertisements and links to third party sites. Kiko Live does not make any representation as to the accuracy or suitability of any of the information contained in those advertisements or sites and does not accept any responsibility or liability for the conduct or content of those advertisements and sites and the offerings made by the third parties.</p>
                                    <p>Advertising keeps Kiko Live and many of the websites and services you use free of charge. We work hard to make sure that ads are safe, unobtrusive, and as relevant as possible.</p>
                                    <p>Third party advertisements and links to other sites where goods or services are advertised are not endorsements or recommendations by Kiko Live of the third party sites, goods or services. Kiko Live takes no responsibility for the content of any of the ads, promises made, or the quality/reliability of the products or services offered in all advertisements.</p>
                                    <p><strong>Testimonials Disclosure</strong></p>
                                    <p>Any testimonials provided on this platform are opinions of those providing them. The information provided in the testimonials is not to be relied upon to predict results in your specific situation. The results you experience will be dependent on many factors including but not limited to your level of personal responsibility, commitment, and abilities, in addition to those factors that you and/or Kiko Live may not be able to anticipate.</p>
                                    <p>We will give honest testimonials to our visitors regardless of any discount. Any product or service that we test are individual experiences, reflecting real life experiences. The testimonials could be displayed on audio, text or video and are not necessarily representative of all of those who will use our products and/or services.</p>
                                    <p>Kiko Live does not guarantee the same results as the testimonials given on our platform. Testimonials presented on Kiko Live are applicable to the individuals writing them, and may not be indicative of future success of any other individuals.</p>
                                    <p>Please don’t hesitate to contact us if you would like to know more about testimonials, discounts, or any of the products/services that we review.</p>
                                    <p><strong>Your Consent</strong></p>
                                    <p>We’ve updated our Disclaimer to provide you with complete transparency into what is being set when you visit our site and how it’s being used. By using our website/app, registering an account, or making a purchase, you hereby consent to our Disclaimer and agree to its terms.</p>
                                    <p><strong>Changes To Our Disclaimer</strong></p>
                                    <p>Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated Disclaimer. If you do not want to agree to this or any updated Disclaimer, you can delete your account.</p>
                                    <p><strong>Contact Us</strong></p>
                                    <p>Don’t hesitate to contact us if you have any questions regarding this Disclaimer.</p>
                                    <p>Email: <a href="mailto:info@kiko.media"><strong> info@kiko.media</strong></a></p>
                                </div>
                            </article>
                        </div>

                    </div>
                </div>

            </div>
            <footer className="page-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer-widget-area">
                                <div className="widget widget_text">
                                    <div className="textwidget">
                                        <div className="kiko-logo">
                                            <img src={KikoLogo} />
                                        </div>
                                        <p style={{ color: "#ffffffbf" }}>
                                            614, Avior Corporate Park, LBS Road, Mulund (W),
                                            Mumbai 400080,
                                            <br />
                                            Maharashtra, India.
                                        </p>
                                        <p>
                                            <a href="mailto:support@kiko.media">
                                                support@kiko.media
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="widget widget_text">
                                    <div className="textwidget">
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://facebook.com/profile.php/?id=100090104332961"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="socialIcon"
                                                >
                                                    <img src={Facebook} alt="Facebook" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.instagram.com/kikoliveapp/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="socialIcon"
                                                >
                                                    <img src={Insta} alt="Instagram" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://in.linkedin.com/company/kikolive"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="socialIcon"
                                                >
                                                    <img src={LinkedIn} alt="LinkedIn" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.youtube.com/@kikolive5631"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="socialIcon"
                                                >
                                                    <img src={Youtube} alt="LinkedIn" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="footer-widget-area">
                                <div className="menu-footer-container">
                                    <ul className="menu">
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/disclaimer"
                                                className="menu-link"
                                            >
                                                Disclaimer
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/privacyPolicy"
                                                className="menu-link"
                                            >
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/deliveryPolicy"
                                                className="menu-link"
                                            >
                                                Delivery Policy
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/returnRefundPolicy"
                                                className="menu-link"
                                            >
                                                Return & Refund Policy
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://sellers.kiko.live/termsCondition"
                                                className="menu-link"
                                            >
                                                Terms & Conditions
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="downloadAppButtons">
                                    <div className="AppButtons">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://apps.apple.com/in/app/kiko-live/id1567183166"
                                            className="downloadLink"
                                        >
                                            <img src={AppStore} alt="App Store" />
                                        </a>
                                    </div>
                                    <div className="AppButtons">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://play.google.com/store/apps/details?id=live.kiko.user&pcampaignid=web_share"
                                            className="downloadLink"
                                        >
                                            <img src={PlayStore} alt="Play Store" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};
export default Disclaimer;