import React from "react";

const Info = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        fill="currentColor"
        d="M32 6a26 26 0 1026 26A26.027 26.027 0 0032 6zm0 48a22 22 0 1122-22 22.025 22.025 0 01-22 22zm4-10a2 2 0 01-2 2 4 4 0 01-4-4V32a2 2 0 010-4 4 4 0 014 4v10a2 2 0 012 2zm-8-23a3 3 0 116 0 3 3 0 01-6 0z"
      ></path>
    </svg>
  );
}

export default Info;
