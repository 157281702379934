import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/component";
import LeftArrow from "../../components/svgicons/leftArrow";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
// import RightArrow from "../../components/svgicons/right-arrow";
// import ProductImg from "../../images/fashionImg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { sellerDetails } from "../../components/common/utils";

const ProductDetails = (props) => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [quantity, setQuantity] = useState(1);
  const [addToCartText, setAddToCartText] = useState("Add To Cart");

  const location = useLocation();
  const navigate = useNavigate();

  const { catalogData, catalogDataArray } = location.state;

  const [catalogDataTemp, setCatalogDataTemp] = useState([]);

  console.log("Des : ", catalogData);

  let cartItems =
    JSON.parse(localStorage.getItem("cart_" + sellerDetails()?._id)) || [];
  const itemIndex = cartItems.filter((item) => item._id === catalogData?._id);

  const [singleCatalogData, setSingleCatalogData] = useState(() => {
    if (itemIndex && itemIndex.length > 0) {
      setQuantity(itemIndex[0].quantity);
      return { ...itemIndex[0]?.catalogData, quantity: itemIndex[0].quantity };
    } else return { ...catalogData };
  });

  const [cartItemLength, setCartItemLength] = useState(cartItems?.length);

  useEffect(() => {
    catalogs();
  }, [catalogDataArray]);

  const catalogs = () => {
    console.log("CATA : ", catalogDataArray);
    const arr = catalogDataArray.catalogues.filter(function (item) {
      return item !== catalogData;
    });
    setCatalogDataTemp(() => {
      return {
        ...arr,
        catalogues: arr?.map((data) => {
          const index = cartItems.findIndex((item) => item._id === data._id);
          if (index !== -1) {
            return { ...data, quantity: cartItems[index].quantity };
          } else {
            return { ...data, quantity: 1 };
          }
        }),
      };
    });
  };

  const cartData = {
    _id: "",
    quantity: 0,
    catalogData: {},
  };

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItems.findIndex((item) => item._id === itemId);
    if (newQuantity > parseInt(singleCatalogData.availableQuantity)) {
      alert(
        "Available Quantity is only " + singleCatalogData.availableQuantity
      );
    } else {
      if (itemIndex !== -1) {
        // Update the quantity of the item
        let updatedCartItems = [...cartItems];
        if (newQuantity === 0) {
          const arr = updatedCartItems.filter(function (item) {
            return item !== updatedCartItems[itemIndex];
          });
          updatedCartItems = arr;
          toggleButtons(itemId);
        } else {
          updatedCartItems[itemIndex].quantity = newQuantity;
        }
        localStorage.setItem(
          "cart_" + sellerDetails()?._id,
          JSON.stringify(updatedCartItems)
        );
        catalogs();
        setCartItemLength(updatedCartItems?.length);
      } else {
        cartData._id = singleCatalogData._id;
        cartData.quantity = newQuantity;
        cartData.catalogData = singleCatalogData;
        cartItems.push(cartData);
        localStorage.setItem(
          "cart_" + sellerDetails()?._id,
          JSON.stringify(cartItems)
        );
        setCartItemLength(cartItems?.length);
      }
    }
  };

  function toggleButtons(id) {
    var addElement = document.getElementById(id);
    var addWithPlusMinusElement = document.getElementById(`ui${id}`);
    if (addElement.style.visibility === "hidden") {
      addElement.style.visibility = "visible";
      addWithPlusMinusElement.style.visibility = "hidden";
    } else {
      addElement.style.visibility = "hidden";
      addWithPlusMinusElement.style.visibility = "visible";
    }
  }
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header
        cartItemLength={cartItemLength}
        func={(obj) => {
          setSingleCatalogData(obj);
        }}
      />
      <div className="productSection">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12">
              <div className="d-flex gap-5 py-3">
                <p className="m-0" style={{ cursor: "pointer" }}>
                  <LeftArrow
                    className="me-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  Back
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <Slider
                slidesToShow={1}
                slidesToScroll={1}
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                arrows={false}
                className="product-slider"
              >
                {singleCatalogData &&
                singleCatalogData?.productImages?.length > 0
                  ? singleCatalogData?.productImages.map((data) => {
                      return (
                        <div className="prodcutImage">
                          <img src={data} alt="Product Catalog" />
                        </div>
                      );
                    })
                  : null}
              </Slider>
              {singleCatalogData?.productImages?.length === 1 ? null : (
                <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={
                    singleCatalogData?.productImages?.length === 2
                      ? 2
                      : singleCatalogData?.productImages?.length > 2
                      ? 3
                      : 3
                  }
                  swipeToSlide={true}
                  focusOnSelect={true}
                  arrows={true}
                  className="product-slider-as-nav"
                >
                  {singleCatalogData &&
                  singleCatalogData?.productImages?.length > 0
                    ? singleCatalogData?.productImages?.map((data) => {
                        return (
                          <div className="prodcutNavImage">
                            <img src={data} alt="Product Catalog" />
                          </div>
                        );
                      })
                    : null}
                </Slider>
              )}
            </div>
            <div className="col-md-8">
              <div className="product-Details pt-3">
                <h3>{singleCatalogData?.productName}</h3>
                {/* <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={20}
                      isHalf={true}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ffd700"
                    /> */}
                <div className="d-flex align-items-center gap-2">
                  <span className="productRate">
                    ₹{singleCatalogData?.discountedPrice}
                  </span>
                  <span className="line-thorugh">
                    ₹{singleCatalogData.price}
                  </span>
                </div>

                {/* <hr className="mb-2 mt-2" />
                    <div className="d-flex gap-5">
                      <div>
                        <label className="filterLabel">Available Size</label>
                        <div class="collapse" id="collapseExample3" data-parent="#myGroup">
                          <div id="myGroup2">
                            <div class="row">
                              <div class="col-lg-12">
                                <div data-toggle="collapse" href="#collapseExample21" role="button" aria-expanded="false" aria-controls="collapseExample21">
                                  <div className="size">
                                    <span className={SSize ? "active" : ""} onClick={() => setSSize(!SSize)}>
                                      S
                                    </span>
                                    <span className={MSize ? "active" : ""} onClick={() => setMSize(!MSize)}>
                                      M
                                    </span>
                                    <span className={LSize ? "active" : ""} onClick={() => setLSize(!LSize)}>
                                      L
                                    </span>
                                    <span className={XLSize ? "active" : ""} onClick={() => setXLSize(!XLSize)}>
                                      XLe
                                    </span>
                                    <span className={XXLSize ? "active" : ""} onClick={() => setXXLSize(!XXLSize)}>
                                      XXL
                                    </span>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="filterLabel">Available Color</label>
                        <div className="colorFilter">
                          <input type="radio" name="color" id="red" value="red" />
                          <label for="red"><span class="red"></span></label>

                          <input type="radio" name="color" id="green" />
                          <label for="green"><span class="green"></span></label>

                          <input type="radio" name="color" id="yellow" />
                          <label for="yellow"><span class="yellow"></span></label>
                        </div>
                      </div>
                    </div> */}
                <hr className="mb-2 mt-2" />
                <label className="filterLabel">
                  <b>Last {singleCatalogData.availableQuantity} left</b> - make
                  it yours!
                </label>
                <div class="addtocart-selector">
                  <div class="addtocart-qty">
                    <div
                      class="addtocart-button button-down minus"
                      onClick={() => {
                        setQuantity(quantity === 1 ? quantity : quantity - 1);
                      }}
                    >
                      -
                    </div>
                    <input
                      type="text"
                      class="addtocart-input"
                      value={quantity}
                    />
                    <div
                      class="addtocart-button button-up"
                      onClick={() => {
                        setQuantity(
                          quantity ===
                            parseInt(singleCatalogData.availableQuantity)
                            ? quantity
                            : quantity + 1
                        );
                      }}
                    >
                      +
                    </div>
                  </div>
                  <Spin
                    spinning={false}
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 20,
                        }}
                        spin
                      />
                    }
                  >
                    <button
                      type="button"
                      className="btn Dark-btn"
                      onClick={() => {
                        addToCartText === "Go To Cart"
                          ? navigate("/cart")
                          : updateCartItemQuantity(
                              singleCatalogData._id,
                              quantity,
                              singleCatalogData
                            );
                        setAddToCartText("Go To Cart");
                      }}
                    >
                      {addToCartText}
                    </button>
                  </Spin>
                </div>
                <hr className="mb-2 mt-2" />
                <div className="product-text">
                  <h6>Product Description</h6>
                  <p>{singleCatalogData?.description}</p>
                  <table className="productDesc-Details">
                    <tr>
                      <td>Style code/Product ID</td>
                      <td>{singleCatalogData?.productId}</td>
                    </tr>
                    <tr>
                      <td>Country Of Origin</td>
                      <td>India</td>
                    </tr>
                    <tr>
                      <td>Net Weight</td>
                      <td>
                        {singleCatalogData?.weight}{" "}
                        {singleCatalogData?.weightUnit}
                      </td>
                    </tr>
                    <tr>
                      <td>Cancellable </td>
                      <td>{singleCatalogData?.isCancellable ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Returnable </td>
                      <td>{singleCatalogData?.isReturnable ? "Yes" : "No"}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-12 pt-5 ">
              {/* {catalogDataTemp && catalogDataTemp?.catalogues && (
                <div className="d-flex justify-content-between">
                  <h3>Other Products from {catalogDataArray?.categoryName}</h3>
                  <p
                    onClick={() =>
                      navigate("/viewAllProduct", {
                        state: catalogDataArray?.categoryName,
                      })
                    }
                    className="view-all m-0 mb-2"
                    style={{cursor: "pointer"}}
                  >
                    View all
                  </p>
                </div>
              )} */}
              <div className="ProductList-grid mb-3">
                {catalogDataTemp &&
                  catalogDataTemp?.catalogues?.map((data, index) => {
                    return (
                      <div className="productCard">
                        <div className="d-flex justify-content-between gap-3">
                          <div
                            className="productDetail"
                            onClick={() => {
                              setSingleCatalogData(data);
                              setQuantity(data.quantity);
                              goToTop();
                            }}
                          >
                            <div>
                              <h2 className="productTitle">
                                {data.productName}
                              </h2>
                              <p className="productDes">
                                {data.description.slice(0, 160)}
                              </p>
                            </div>
                            <div>
                              <div className="productRate">
                                <h1>₹{data.discountedPrice}</h1>
                                <span className="line-thorugh">
                                  ₹{data.price}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="productimage"> */}
                          <div className="productimage">
                            <div
                              onClick={() => {
                                setSingleCatalogData(data);
                                setQuantity(data.quantity);
                                goToTop();
                              }}
                              className="productImg"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={data.productImages[0]}
                                alt="Product Catalog"
                              />
                            </div>
                            <div className="add--cart-btn">
                              <button
                                id={data._id}
                                className="addBtn"
                                onClick={() => {
                                  toggleButtons(data._id);
                                  updateCartItemQuantity(
                                    data._id,
                                    data.quantity,
                                    data
                                  );
                                }}
                              >
                                Add
                              </button>
                              <div
                                style={{ visibility: "hidden" }}
                                id={`ui${data._id}`}
                                className="addtocart-qty"
                              >
                                <div
                                  class="addtocart-button button-down minus"
                                  onClick={() => {
                                    updateCartItemQuantity(
                                      data._id,
                                      data.quantity === 1
                                        ? 0
                                        : (data.quantity -= 1),
                                      data
                                    );
                                  }}
                                >
                                  -
                                </div>
                                <input
                                  type="number"
                                  class="addtocart-input"
                                  value={data.quantity}
                                />
                                <div
                                  class="addtocart-button button-up"
                                  onClick={() => {
                                    updateCartItemQuantity(
                                      data._id,
                                      (data.quantity += 1),
                                      data
                                    );
                                  }}
                                >
                                  +
                                </div>
                              </div>
                              <div />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductDetails;
