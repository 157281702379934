import React, { useEffect, useState } from "react";
import Header from "../Header/component";
import LeftArrow from "../../components/svgicons/leftArrow";
import API from "../../api";
import { handleError } from "../../components/common/utils";
import { GET_BUYER_ORDER } from "../../api/apiList";
import Loader from "../../components/common/loader";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
const Order = () => {
  const [orderData, setOrderData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const Orders = async () => {
    setLoading(true);
    const body = {
      page: page,
      requestType: "customer-my-orders",
    };

    try {
      const response = await API.post(GET_BUYER_ORDER, body);
      if (response) {
        console.log("ORDER: RESPONSE", response);
        setCount(response?.data?.data?.totalDocs);
        setOrderData(response?.data?.data?.docs);
      }
      setLoading(false);
    } catch (error) {
      handleError(error);
      setLoading(false);
      setOrderData([]);
    }
  };
  useEffect(() => {
    Orders();
  }, []);
  const orderStatus = () => {
    return;
  };

  return (
    <>
      <Header />
      <div className="orderSection">
        <div className="container">
          <div className="row">
            {loading ? (
              <div className="dotLoader">
                <Loader />
              </div>
            ) : (
              <div className="col-lg-12">
                <div className="d-flex align-items-center gap-2 py-3">
                  <LeftArrow
                    className="me-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <h5 className="m-0">Orders</h5>
                </div>

                {orderData && orderData?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Order Id </th>
                          <th>Seller</th>
                          <th>Order Date & Time</th>
                          <th>Amount</th>
                          <th>Delivery Charges</th>
                          <th>Payment Method</th>
                          <th>Order Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData.map((order, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <p
                                  style={{
                                    color: "blue",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/orderDetails", {
                                      state: { orderId: order?._id },
                                    });
                                  }}
                                >
                                  #{order?.orderId}
                                </p>
                              </td>
                              <td>{order?.vendorId?.storeName}</td>
                              <td>
                                {dayjs(order?.createdAt).format(
                                  "MMM D, YYYY h:mm A"
                                )}
                              </td>
                              <td>{order?.orderAmount}</td>
                              <td>{order?.shippingAmount}</td>
                              <td>
                                {order?.orderPaymentMode === "SelfPayment"
                                  ? "Cash On Delivery"
                                  : "Prepaid"}
                              </td>
                              <td>
                                <span
                                  className={
                                    order?.orderStatus === "order-cancelled"
                                      ? "badge-soft-danger text-uppercase"
                                      : "badge-soft-secondary text-uppercase"
                                  }
                                >
                                  {order?.deliveryVendorStatus &&
                                  order?.orderStatus !== "order-cancelled"
                                    ? order?.deliveryVendorStatus ===
                                      "parcel_delivered"
                                      ? "Parcel Delivered"
                                      : "Parcel Picked Up"
                                    : order?.orderPaymentMode === "SelfPayment"
                                    ? order?.orderStatus === "payment-completed"
                                      ? "ORDER PLACED"
                                      : order?.orderStatus
                                    : order?.orderStatus}
                                </span>
                              </td>
                            </tr>
                            // badge-soft-danger
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="notfounderror">
                    <h1 className="no-found-data">Order not found</h1>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Order;
// const order_status = item.deliveryVendorStatus && item.deliveryVendorStatus === "parcel_delivered" || item.deliveryVendorStatus && item.deliveryVendorStatus === "parcel_picked_up" || item.deliveryVendorStatus && item.deliveryVendorStatus === "courier_assigned" ? item.deliveryVendorStatus : item?.orderStatus ?? ""

// renderMyOrdersStatus = (status) => {
//     const { route, role } = this.props;
//     const { statusTxt, orderImg } = this.getStyles()
//     const fromScreen = route && route.params && route.params.fromScreen ? route.params.fromScreen : ""
//     const fromSellerZone = ["vendor", "semivendor"].includes(role) && fromScreen === 'SellerZone' ? true : false

//     switch (status) {
//       case "order-cancelled":
//         return "CANCELLED"
//       case "payment-completed":
//         return "ORDER RECEIVED"
//       case "order-expired":
//         return "EXPIRED"
//       case "order-ready-to-pickup":
//         return "PICK UP INITIATED"
//       case "courier_assigned":
//         return
//       case "parcel_picked_up":
//         return (
//           <View style={[orderImg, { backgroundColor: Colors.lightGreenType }]}>
//             <Text style={[statusTxt, { textAlign: 'center' }]} >{"ORDER\nPICKED UP"}</Text>
//           </View>
//         )
//       case "parcel_delivered":
//         return (
//           <View style={[orderImg, { backgroundColor: Colors.lightGreenType }]}>
//             <Text style={[statusTxt, { textAlign: 'center' }]} >{"ORDER\nCOMPLETED"}</Text>
//           </View>
//         )
//       default:
//         return (
//           <View style={orderImg}>
//             <Text style={[statusTxt, { textAlign: 'center' }]}>{""}</Text>
//           </View>
//         )
//     }
//   }

// {
//     "orderStatus": "order-cancelled",
//     "deliveryVendorStatus": "",
//     "orderRoomId": "",
//     "orderAmount": 3,
//     "orderDiscount": 0,
//     "payableAmount": 0,
//     "coinUsed": 0,
//     "maxDiscount": 0,
//     "shippingAmount": 0,
//     "orderDescription": "",
//     "orderQuantity": 1,
//     "orderDeliveryMode": "KikoDelivery",
//     "orderPaymentMode": "SelfPayment",
//     "orderMode": "Offline",
//     "dunzoTransactionId": "",
//     "razorPayOrderId": null,
//     "razorPayTransactionId": null,
//     "currency": "INR",
//     "paymentGateway": "razorpay",
//     "coinAmount": "0",
//     "rewardType": "",
//     "coinOrderId": "",
//     "couponCode": "",
//     "witdrawalStatus": "Pending",
//     "trackingUrl": "",
//     "kikoDeliveryStatus": "",
//     "orderExpiresTime": 180,
//     "orderLink": "https://s.kiko.live/oo?id=kiko_2819",
//     "buyerPhoneNumber": "3333443333",
//     "buyerName": "33",
//     "isRefund": false,
//     "deliveryRazorPayOrderId": null,
//     "deliveryRazorPayTransactionId": null,
//     "actualShippingAmount": 0,
//     "shippingAmountDiscount": 0,
//     "createdFrom": "app",
//     "_id": "63e4f45286e4710ae6286133",
//     "deviceId": "B3899D4F-0236-4DDE-AD23-772B25D71D92",
//     "agentId": {
//         "photo": "",
//         "phone": "+919009120913",
//         "_id": "6386154b42674fabf7bdabf4",
//         "name": "Vijendra Patidar"
//     },
//     "productItemList": [
//         {
//             "name": "3",
//             "price": 3,
//             "_id": "63e4f45286e4710ae6286134"
//         }
//     ],
//     "totalWeight": 3,
//     "vendorId": {
//         "photo": "",
//         "phone": "+919009120913",
//         "_id": "6386154b42674fabf7bdabf4",
//         "name": "Vijendra Patidar",
//         "storeName": "Vijendra Kiko Vijendra Kiko Vijendra Kiko Vijendra Kiko Vijendra Kiko"
//     },
//     "addressAddedBy": "63e4f3da86e4710ae62860e7",
//     "createdBy": "6386154b42674fabf7bdabf4",
//     "orderLocation": {
//         "city": "",
//         "state": "",
//         "zipcode": "",
//         "contactName": "",
//         "latitude": "22.719568",
//         "longitude": "75.857727",
//         "contactPhone": "",
//         "addressType": "",
//         "address_line1": "",
//         "landmark": "",
//         "address_line2": ""
//     },
//     "userId": {
//         "photo": "",
//         "phone": "+913333443333",
//         "_id": "63e4f3da86e4710ae62860e7",
//         "name": "Test Store 4433"
//     },
//     "orderTracking": [
//         {
//             "_id": "63e4f45286e4710ae6286135",
//             "status": "order-place",
//             "createdAt": "2023-02-09T13:25:38.358Z"
//         },
//         {
//             "_id": "642540b3c2f1e859862bac7b",
//             "status": "order-cancelled",
//             "createdAt": "2023-03-30T07:56:35.898Z"
//         }
//     ],
//     "orderId": "kiko_2819",
//     "paymentTracking": [],
//     "kikoDeliveryStatusTracker": [],
//     "createdAt": "2023-02-09T13:25:38.362Z",
//     "updatedAt": "2023-03-30T07:56:35.898Z",
//     "__v": 0,
//     "userAddress": {
//         "latitude": 22.71387766021781,
//         "longitude": 75.85850408300757,
//         "contactName": "Ter",
//         "contactPhone": "3333443333",
//         "city": "Indore Division",
//         "state": "Madhya Pradesh",
//         "zipcode": "452007",
//         "address_line1": "Indore Division",
//         "address_line2": "12, Maharaja Tukoji Rao Holker Cloth Market, Indore, Madhya Pradesh 452007, India",
//         "landmark": "",
//         "addressType": "home"
//     },
//     "cancelledBy": "kikouser",
//     "cartItem": []
// }
