
import React, { useState } from 'react';
import { get } from 'lodash';
import axios from 'axios'
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../../components/common/loader";
import { sellerDetails } from '../../components/common/utils';

const RazorpayModule = (props) => {
  const Razorpay = useRazorpay();
  const [loadingSelectAddress, setLoadingSelectAddress] = useState(false);
  let orderDetails = props.orderDetails;
  const orderAmount = props.orderAmount;
  const transactionFee = props.transactionFee;
  const paymentMethod = props.paymentMethod;

  console.log("RAZORPAY : PROPS ", props, orderDetails);
  const navigate = useNavigate();

  const updateRazorPayId = async () => {
    let payableAmount = 0;
    payableAmount = orderAmount
    if (transactionFee > 0) {
      payableAmount = payableAmount + transactionFee;
    }
    const razorpayAccount = get(orderDetails, 'orderPaymentMode', '') === 'KikoPayment' ? 'noddle-account' : 'old-account';
    const options = {
      method: 'post',
      url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/order/update-razorpayid-v2`,
      data: {
        _id: get(orderDetails, "_id", ""),
        payableAmount,
        transactionFee,
        paymentMode: get(orderDetails, 'orderPaymentMode', ''),
        razorpayAccount
      }
    }
    const result = await axios(options)
    return get(result, 'data.data', {})
  }

  const handlePayment = async (e) => {
    orderDetails = await updateRazorPayId()
    const orderId = get(orderDetails, 'orderId', null);
    const razorpayId = get(orderDetails, 'orderPaymentMode', '') === 'KikoPayment' ? get(orderDetails, 'razorPayOrderId', null) : get(orderDetails, 'deliveryRazorPayOrderId', null);
    const razorpayKey = get(orderDetails, 'orderPaymentMode', '') === 'KikoPayment' ? process.env.REACT_APP_RAZORPAY_NODAL_KEY : process.env.REACT_APP_RAZORPAY_KEY;
    const options = {
      key: razorpayKey,
      currency: "INR",
      name: "Kiko Live",
      description: "Kiko Seller Payment",
      image: "",
      order_id: razorpayId,
      handler: (response) => {
        let values = { transactionFee }
        if (get(orderDetails, 'orderPaymentMode', '') === 'KikoPayment') {
          values.razorPayTransactionId = response.razorpay_payment_id
        } else {
          values.orderStatus = 'payment-completed'
          values.deliveryRazorPayTransactionId = response.razorpay_payment_id
        }
        axios.put(`${process.env.REACT_APP_KIKO_API_BASE_URL}/order/update-offline-order/${orderId}`, values)
          .then(res => {
            if (get(res, 'status', false)) {
              let payableAmount = orderAmount;
              if (transactionFee > 0) {
                payableAmount = orderAmount + transactionFee;
              }
              localStorage.removeItem("cart_" + sellerDetails()?._id);
              navigate(localStorage.getItem("slug"), { state: { paymentSuccessful: true, orderAmount: payableAmount } })
              // props.history.push({
              //   pathname: '/',
              //   state: { paymentSuccessful: true },
              // })
            }
          })
          .catch(e => {
            console.log('error', e)
          })
      },
      prefill: {
        name: get(orderDetails, 'buyerName', ''),
        email: "",
        contact: get(orderDetails, 'buyerPhoneNumber', ''),
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
      config: {
        display: {
          hide: paymentMethod === 'upi' ? [
            { method: 'card' },
            { method: 'wallet' },
            { method: 'netbanking' },
            { method: 'paylater' }
          ] : paymentMethod === 'other' ? [{ method: 'upi' }] : [],
        }
      }
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  return (
    <div className='button-loader  mb-4'>
      <button className='btn Dark-btn Proceed-btn ' onClick={handlePayment}>{loadingSelectAddress ? "" : "Proceed"}</button>
      <div className="dotLoader">
        {loadingSelectAddress && <Loader />}
      </div>
    </div>
  );
}

export default RazorpayModule